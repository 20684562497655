import {
    FmRunVerificationRequest,
    FmRunVerificationResultList,
} from '../api/fmRun/fmRunVerificationResult';

export const mockFmRunVerificationRequest: FmRunVerificationRequest = {
    tenantName: 'paperless',
};

export const mockFmRunVerificationResultList: FmRunVerificationResultList = {
    data: [
        {
            EINC: 123,
            CompanyFullName: 'Abc',
            FmRunId: 987,
            FmRunVerificationStatusId: 1,
            FmRunVerificationStatusName: 'Pass',
            FmRunVerificationNote: 'Ready to post',
        },
        {
            EINC: 456,
            CompanyFullName: 'Def',
            FmRunId: 988,
            FmRunVerificationStatusId: 2,
            FmRunVerificationStatusName: 'Fail',
            FmRunVerificationNote: 'Error: XYZ',
        },
        {
            EINC: 789,
            CompanyFullName: 'Ghi',
            FmRunId: 989,
            FmRunVerificationStatusId: 1,
            FmRunVerificationStatusName: 'Pass',
            FmRunVerificationNote: 'Ready to post',
        },
        {
            EINC: 124,
            CompanyFullName: 'Jkl',
            FmRunId: 990,
            FmRunVerificationStatusId: 1,
            FmRunVerificationStatusName: 'Skip',
            FmRunVerificationNote: 'Skipped',
        },
        {
            EINC: 457,
            CompanyFullName: 'Mno',
            FmRunId: 991,
            FmRunVerificationStatusId: 2,
            FmRunVerificationStatusName: 'Warning',
            FmRunVerificationNote: 'Warning: ZXY',
        },
    ],
    totalCount: 5,
};
