import { useEffect, useState } from 'react';

// Msal imports
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import {
    AccountInfo,
    InteractionRequiredAuthError,
    InteractionStatus,
    InteractionType,
} from '@azure/msal-browser';
import { loginRequest, msalConfig } from '../../msal/authConfig';

// Sample app imports
import { GraphData, ProfileData } from '../api-authorization/ProfileData';
import { ErrorComponent } from '../api-authorization/AuthErrorComponent';
import { callMsGraph } from '../../msal/utils/MsGraphApiCall';

// Material-ui imports
import Paper from '@mui/material/Paper';
import { BasicContainer } from '../common/containers/BasicContainer';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const ProfileContent = () => {
    console.log('You hit the profile content component!');

    const [graphData, setGraphData] = useState<GraphData | null | undefined>(
        null,
    );
    const { instance, inProgress } = useMsal();

    const { result, error } = useMsalAuthentication(InteractionType.Popup, {
        ...loginRequest,
        redirectUri: msalConfig.auth.redirectUri, // e.g. /redirect
    });

    useEffect(() => {
        if (!!graphData) {
            // We already have the data, no need to call the API
            console.log(`Graph data: ${graphData.displayName}`);
            return;
        }

        if (!!error) {
            // Error occurred attempting to acquire a token, either handle the error or do nothing
            console.log(`Error: ${error.errorMessage}`);
            return;
        }

        if (result) {
            console.log(`Result username: ${result.account.username}`);
            callMsGraph().then((response) => setGraphData(response));
        }
    }, [error, result, graphData]);

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph()
                .then((response) => setGraphData(response))
                .catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenPopup({
                            ...loginRequest,
                            account: instance.getActiveAccount() as AccountInfo,
                        });
                    }
                });
        }
    }, [inProgress, graphData, instance]);

    if (error) {
        return <ErrorComponent error={error} />;
    }

    const theme = useTheme();

    return (
        <Box sx={{ p: theme.spacing(4) }}>
            {graphData ? <ProfileData graphData={graphData} /> : null}
        </Box>
    );
};

export function ProfileUseMsalAuthenticationHook() {
    return <ProfileContent />;
}
