import {
    FMSTabContent,
    FMSTabContentProps,
} from '../../../../common/containers/FMSTabs';
import { useCallback, useEffect, useState } from 'react';
import {
    FmRunVerificationRequest,
    FmRunVerificationResultList,
} from '../../../../../api/fmRun/fmRunVerificationResult';
import {
    mockFmRunVerificationRequest,
    mockFmRunVerificationResultList,
} from '../../../../../mockData/mockFmRunVerificationResultList';
import { OptionalString } from '../../../../../types/UtilityTypes';
import { VerificationSummaryDataGrid } from './VerificationDataGrid';

export const VerificationSummaryTabContent = (props: FMSTabContentProps) => {
    const { currentIndex, index } = props;

    const [fmRunVerificationResultList, setFmRunVerificationResults] =
        useState<FmRunVerificationResultList | null>(null);
    const [dataGridIsLoading, setDataGridIsLoading] = useState(false);

    const loadNewResults = useCallback(
        async (request: FmRunVerificationRequest) => {
            setDataGridIsLoading(true);

            const response = await getFakeVerificationList(request);

            // TODO replace this in a future PR with something like:
            //      if(response.isSuccess)
            if (!!response) {
                setFmRunVerificationResults(response);
            } else {
                alert('This will be an error message');
            }

            setDataGridIsLoading(false);
        },
        [],
    );

    // TODO replace this with an actual API call when the API is operational
    async function getFakeVerificationList(
        fmRunVerificationRequest: FmRunVerificationRequest,
    ): Promise<FmRunVerificationResultList | null | undefined> {
        return new Promise(function (resolve, reject) {
            if (mockFmRunVerificationResultList) {
                // Setting timeout to simulate loading from the API and display the built-in loading skeleton...
                setTimeout(() => {
                    resolve(
                        mockFmRunVerificationResultList as FmRunVerificationResultList,
                    );
                }, 500);
            } else {
                setTimeout(() => {
                    reject('You should have never seen this' as OptionalString);
                }, 500);
            }
        });
    }

    // TODO replace this with useMutation from @tanstack/react-query
    useEffect(() => {
        loadNewResults(mockFmRunVerificationRequest);
    }, [mockFmRunVerificationRequest]);

    return (
        <FMSTabContent currentIndex={currentIndex} index={index}>
            <VerificationSummaryDataGrid
                fmRunVerificationResultList={fmRunVerificationResultList}
                isLoading={dataGridIsLoading}
            />
        </FMSTabContent>
    );
};
