import { OptionalString } from '../types/UtilityTypes';
import {
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from '@mui/material/colors';

export const muiColors = {
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
};

export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type ColorValue = RGB | RGBA | HEX;

export type FMSColor = {
    name: string;
    description?: OptionalString;
    value: ColorValue;
};

const fmsPrimary100: FMSColor = {
    name: 'Primary 100%',
    description: 'The main color for the app',
    value: '#00838C',
};

const fmsPrimary50: FMSColor = {
    name: 'Primary 50%',
    description: 'The main color at 50% saturation',
    value: '#80C1C5',
};

const fmsSecondary100: FMSColor = {
    name: 'Secondary 100%',
    description: 'The secondary color for the app',
    value: '#DBF4EE',
};

const fmsBackground: FMSColor = {
    name: 'Background',
    description: 'The color of the web app background',
    value: '#fbfbfb',
};

const coolGrey100: FMSColor = {
    name: 'Cool Grey',
    value: '#616565',
};
const coolGrey80: FMSColor = {
    name: 'Cool Grey 80%',
    value: '#757979',
};
const coolGrey60: FMSColor = {
    name: 'Cool Grey 60%',
    value: '#9ea2a2',
};
const coolGrey40: FMSColor = {
    name: 'Cool Grey 40%',
    value: '#bcc1c1',
};
const coolGrey20: FMSColor = {
    name: 'Cool Grey 20%',
    value: '#dfe4e4',
};

const black100: FMSColor = {
    name: 'Black 100%',
    value: '#000000',
};

const black80: FMSColor = {
    name: 'Black 80%',
    value: '#262626',
};

const black60: FMSColor = {
    name: 'Black 60%',
    value: '#7b7b7b',
};

const black40: FMSColor = {
    name: 'Black 40%',
    value: '#a5a5a5',
};

const black20: FMSColor = {
    name: 'Black 20%',
    value: '#c3c3c3',
};

const greyFalse: FMSColor = {
    name: 'Grey False',
    description: 'Grey used for false values',
    value: '#737373',
};

const white100: FMSColor = {
    name: 'White',
    description: 'The one and only white',
    value: '#FFFFFF',
};

const success100: FMSColor = {
    name: 'Success 100%',
    description: 'Displayed when the user accomplishes a task',
    value: '#4CAF50',
};

const successMuted: FMSColor = {
    name: 'Success Muted',
    description: 'Success but less vibrant',
    value: '#E0EEDC',
};

const error100: FMSColor = {
    name: 'Error 100%',
    description: 'Displayed when something is wrong',
    value: '#F44336',
};

const errorMuted: FMSColor = {
    name: 'Error Muted',
    description: 'Error but less vibrant',
    value: '#EEC6C2',
};

const warningMuted: FMSColor = {
    name: 'Warning Muted',
    description: 'Warning but less vibrant',
    value: '#F5EAB3',
};

const infoMuted: FMSColor = {
    name: 'Skip Muted',
    description: 'Skip but less vibrant',
    value: '#CFE5FE',
};

const purpleStatusMuted: FMSColor = {
    name: 'Purple Status Muted',
    description: 'Purple but muted',
    value: '#EEDFF',
};

const greyStatusMuted: FMSColor = {
    name: 'Grey status muted',
    description: 'Grey but muted',
    value: '#DFDFDF',
};

export const fmsColors = {
    fmsPrimary100,
    fmsPrimary50,
    fmsSecondary100,
    fmsBackground,
    coolGrey100,
    coolGrey80,
    coolGrey60,
    coolGrey40,
    coolGrey20,
    black100,
    black80,
    black60,
    black40,
    black20,
    greyFalse,
    white100,
    success100,
    successMuted,
    error100,
    errorMuted,
    warningMuted,
    infoMuted,
};
