import { FmRunTypeRequest, FmRunTypeResultList } from '../api/fmRun/fmRunType';

const mockFmRunTypeRequest: FmRunTypeRequest = {
    tenantName: 'paperless',
};

export const mockFmRunTypeResultList: FmRunTypeResultList = [
    {
        fmRunTypeId: 1,
        fmRunTypeName: 'Ad Hoc/Initial Wire',
        fmRunTypeDescription: 'Ad Hoc/Initial Wire',
    },
    {
        fmRunTypeId: 2,
        fmRunTypeName: 'FinalWire',
        fmRunTypeDescription: 'FinalWire',
    },
    {
        fmRunTypeId: 3,
        fmRunTypeName: 'Future Week Final Wire',
        fmRunTypeDescription: 'Future Week Final Wire',
    },
];
