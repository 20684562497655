import { Alert, Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorBoundaryFallback: FunctionComponent<FallbackProps> = ({
    error,
    resetErrorBoundary,
}) => {

    return (
        <Alert
            aria-live="assertive"
            severity="error"
            action={
                <Button
                    color="inherit"
                    size="small"
                    onClick={resetErrorBoundary}
                >
                    {"Try Again"}
                </Button>
            }
        >
            {error.message}
        </Alert>
    );
};

export default ErrorBoundaryFallback;