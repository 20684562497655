import WeekendBillResultList, { WeekendBillRequest } from '../api/weekendBill';

export const mockWeekendBillRequest: WeekendBillRequest = {
    tenantName: 'paperless',
};

export const mockWeekendBillResultList: WeekendBillResultList = [
    { weekendBill: '11/17/24', weekStatus: 'Open' },
    { weekendBill: '11/10/24', weekStatus: 'Closed' },
    { weekendBill: '11/03/24', weekStatus: 'Closed' },
    { weekendBill: '10/27/24', weekStatus: 'Closed' },
];
