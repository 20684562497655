import FmPlanResultList, { FmPlanRequest } from '../api/fmPlan/fmPlan';

export const mockFmPlanRequest: FmPlanRequest = {
    tenantName: 'paperless',
};

export const mockFmPlanResultList: FmPlanResultList = [
    {
        fmPlanId: 1,
        fmPlanName: 'Big League Staffing',
        fmPlanDescription: 'BLS 6.0%',
    },
];
