import {
    VerificationRunDetailLogMessageRequest,
    VerificationRunDetailLogMessageResultList,
} from '../api/fmRun/verificationRunDetailLogMessage';

export const mockVerificationRunDetailLogMessageRequest: VerificationRunDetailLogMessageRequest =
    {
        tenantName: 'paperless',
        fmVerificationRunId: 98765,
    };

export const mockVerificationRunDetailLogMessageResultList: VerificationRunDetailLogMessageResultList =
    {
        data: [
            {
                fmVerificationRunMessageId: 54321,
                messageDescription: 'WRI Average',
                logStatus: 'Warning',
                logMessage:
                    'The WRI of this FmRun is 30.0000% average for this client, but amount of WRI is under $50000.00. Warning only. WRI is 610.37.',
                nextStep:
                    'Review FmRun and confirm that WRI is accurate and appropriate for this client before releasing',
                logMessageNote: null,
            },
            {
                fmVerificationRunMessageId: 54322,
                messageDescription: 'Debtor Concentration',
                logStatus: 'Fail',
                logMessage:
                    'Warning: Debtor(s) have concentration > 20.0000%. CustomerIDs are 157305',
                nextStep:
                    'Review debtors and then AR. Email TWCollections with any collections concerns. Confirm client is approved for funding.',
                logMessageNote: null,
            },
        ],
        totalCount: 2,
    };
