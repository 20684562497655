import { RouteProps } from 'react-router-dom';
import { Home, HomeIcon } from './components/pages/Home';
import { Merge } from './types/UtilityTypes';
import FundingRunPage, {
    FundingRunPageIcon,
} from './components/pages/FundingRun/FundingRunPage';
import { FmPlanIcon, FmPlanPage } from './components/pages/FmPlanPage';
import {
    GeneralLedgerIcon,
    GeneralLedgerPage,
} from './components/pages/GeneralLedgerPage';
import { LogoutIcon } from './components/pages/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import { ProfileUseMsalAuthenticationHook } from './components/pages/ProfileUseMsal';

export type AppRoute = Merge<
    {
        path: string;
        displayFullName: string;
        displayShortName: string;
        iconComponent?: JSX.Element;
        disabled?: boolean;
    },
    RouteProps
>;

const HomeRoute: AppRoute = {
    index: true,
    path: '/',
    displayFullName: 'Home',
    displayShortName: 'Home',
    element: <Home />,
    iconComponent: <HomeIcon />,
};

const FundingRunRoute: AppRoute = {
    path: '/FundingRun',
    displayFullName: 'Funding Run',
    displayShortName: 'Funding',
    element: <FundingRunPage />,
    iconComponent: <FundingRunPageIcon />,
};

const FmPlanRoute = {
    path: '/FmPlan',
    displayFullName: 'Funding Plan',
    displayShortName: 'Plan',
    element: <FmPlanPage />,
    iconComponent: <FmPlanIcon />,
    disabled: true,
};

const GeneralLedgerRoute = {
    path: '/GeneralLedger',
    displayFullName: 'General Ledger',
    displayShortName: 'GL',
    element: <GeneralLedgerPage />,
    iconComponent: <GeneralLedgerIcon />,
    disabled: true,
};

export const ProfileRoute = {
    path: '/Profile',
    displayFullName: 'Profile',
    displayShortName: 'Profile',
    element: <ProfileUseMsalAuthenticationHook />,
    iconComponent: <Person2Icon />,
};

export const LogoutRoute = {
    path: '/Logout',
    displayFullName: 'LOG OUT',
    displayShortName: '',
    element: <h1>You have logged out</h1>,
    iconComponent: <LogoutIcon />,
};

export const AppRoutes: Array<AppRoute> = [
    HomeRoute,
    FundingRunRoute,
    FmPlanRoute,
    GeneralLedgerRoute,
    ProfileRoute,
    LogoutRoute,
];

export const NavRoutes: Array<AppRoute> = [
    HomeRoute,
    FundingRunRoute,
    FmPlanRoute,
    GeneralLedgerRoute,
];

export default AppRoutes;
