/* eslint-disable react/prop-types */
import DropdownField, {
    DropdownFieldProps,
} from '../common/form/formControls/DropdownField';
import {
    Merge,
    OptionalNumber,
    OptionalString,
} from '../../types/UtilityTypes';
import { ReactElement, useMemo, useState, KeyboardEvent } from 'react';
import { FieldState } from '../../utils/formStateUtils';
import FormattedValidationMessage from '../common/form/FormattedValidationMessage';
import InlineLoadingErrorMessage from '../InlineLoadingErrorMessage';
import { mockWeekendBillResultList } from '../../mockData/mockWeekendBillResultList';
import WeekendBillResultList, {
    WeekendBillResult,
} from '../../api/weekendBill';

type WeekendBillDropdownProps = Merge<
    Omit<
        DropdownFieldProps<WeekendBillResult, OptionalString, false>,
        'options' | 'multiple'
    >,
    {
        /**
         * If you provide the `fieldState` for a field in your form, this component
         * will use it to set default props for the underlying `DropdownField` components.
         */
        fieldState?: FieldState<OptionalString>;
        container?: ReactElement;
    }
>;

const mapFieldStateToProps = (
    allProps: WeekendBillDropdownProps,
): WeekendBillDropdownProps => {
    const { fieldState, ...props } = allProps;
    if (!fieldState) return props;

    return {
        label: fieldState.label,
        name: fieldState.name,
        value: fieldState.value,
        onChange: (newValue) => {
            fieldState.setValue(newValue);
        },
        required: fieldState.required,
        error: fieldState.isNotValid,
        helperText: (
            <FormattedValidationMessage
                validationMessage={fieldState.validationMessage}
            />
        ),
        ...props,
        disabled: fieldState.formIsSubmitting || props.disabled,
    };
};

const WeekendBillDropdown = (_props: WeekendBillDropdownProps) => {
    const props = _props.fieldState ? mapFieldStateToProps(_props) : _props;

    const {
        container,
        value: weekendBill,
        onChange,
        ...propsForWeekendBillResultDropdown
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [loadingErrorMessage, setErrorMessage] = useState(
        null as string | null,
    );
    const [openState, setOpenState] = useState(false);
    /* We're tracking the highlighted option so that can be set as the value when the user hits the right-arrow key */
    const [highlightedOption, setHighlightedOption] =
        useState<OptionalString>(null);

    const [WeekendBillResults, setWeekendBillResults] =
        useState<WeekendBillResultList | null>(null);
    useMemo(async () => {
        setIsLoading(true);
        const response =
            //await getWeekendBillResultSearchResults(requestSettings);
            mockWeekendBillResultList;
        /*if (!response.isSuccess) {
            setErrorMessage(response.errorMessage);
        } else {*/
        const WeekendBillSortedResults = response.sort((a, b) => {
            const nameA = a.weekendBill.toUpperCase(); // ignore upper and lowercase
            const nameB = b.weekendBill.toUpperCase(); // ignore upper and lowercase
            if (nameA > nameB) {
                return -1;
            }
            if (nameA < nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        }) as WeekendBillResultList | null;
        setWeekendBillResults(WeekendBillSortedResults);
        //}
    }, []);

    const setFieldValue = (newWeekendBillResult: OptionalString) => {
        onChange && onChange(newWeekendBillResult);
    };

    /* 
        In order to reduce the amount of time users need to use their mouse, 
        we're setting a keyboard shortcut to set the current highlighted option as the value 
        and then close the drop down
    */
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'ArrowRight' && openState == true) {
            setFieldValue(highlightedOption);
            setOpenState(false);
        }
    };

    const handleSelectOpen = () => {
        setOpenState(true);
    };
    const handleSelectClose = () => {
        setOpenState(false);
    };

    return (
        <DropdownField
            getOptionValue={(WeekendBillResult) =>
                WeekendBillResult?.weekendBill
            }
            getOptionLabel={(WeekendBillResult) => {
                if (WeekendBillResult?.weekendBill.length > 1) {
                    return `${WeekendBillResult?.weekendBill} (${WeekendBillResult.weekStatus})`;
                } else {
                    return '';
                }
            }}
            onKeyDown={handleKeyDown}
            loading={isLoading}
            {...propsForWeekendBillResultDropdown}
            label={
                propsForWeekendBillResultDropdown.label || 'WeekendBillResult'
            }
            options={WeekendBillResults || []}
            value={weekendBill}
            open={openState}
            onHighlightChange={(event, option, reason) =>
                setHighlightedOption(option?.weekendBill)
            }
            onOpen={handleSelectOpen}
            onClose={handleSelectClose}
            onChange={(newWeekendBillResultId) => {
                setFieldValue(newWeekendBillResultId);
            }}
            {...(!!loadingErrorMessage && {
                error: true,
                helperText: (
                    <InlineLoadingErrorMessage
                        errorMessage={loadingErrorMessage}
                    />
                ),
            })}
        />
    );
};

export default WeekendBillDropdown;
