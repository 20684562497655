import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid2,
    TextField,
    useTheme,
} from '@mui/material';
import { fmsColors } from '../../../../styles/colors';

export const EmployerDetailsPlaceholder = () => {
    const theme = useTheme();
    return (
        <Accordion sx={{ width: '100%' }} expanded={true}>
            <AccordionSummary>Employer Details</AccordionSummary>
            <AccordionDetails>
                <Grid2
                    size={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    columnGap={theme.spacing(1.5)}
                    sx={{ mb: theme.spacing(1.5) }}
                >
                    <TextField
                        label={'User'}
                        defaultValue={'Bob Wob'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Application'}
                        value={'Payclerk'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                            },
                            ['& ::after']: {
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Version'}
                        value={'1.00'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Database Name'}
                        value={'Paperless'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                </Grid2>
                <Grid2
                    size={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    columns={4}
                    columnGap={theme.spacing(1.5)}
                    sx={{ mb: theme.spacing(1.5) }}
                >
                    <TextField
                        label={'User ID'}
                        defaultValue={'987654321'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'User Group'}
                        value={'Payclerk'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                            },
                            ['& ::after']: {
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Site ID'}
                        value={'QEN'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Time Launched'}
                        value={'11/19/2024 12:00:00 PM'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                </Grid2>
                <Grid2
                    size={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    columns={4}
                    columnGap={theme.spacing(1.5)}
                    sx={{ mb: theme.spacing(1.5) }}
                >
                    <TextField
                        label={'Mode Type'}
                        defaultValue={'Entity'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'User Branch'}
                        value={'Disney World - Epcot'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                            },
                            ['& ::after']: {
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Company ID'}
                        value={'4321'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Program Name'}
                        value={'FMS'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                </Grid2>
                <Grid2
                    size={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    columns={4}
                    columnGap={theme.spacing(1.5)}
                    sx={{ mb: theme.spacing(1.5) }}
                >
                    <TextField
                        label={'Mode Name'}
                        defaultValue={'DW Epcot'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Last Updated'}
                        value={'11/19/2024 12:05:56'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                            },
                            ['& ::after']: {
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Company Name'}
                        value={'Disney World'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Machine Name'}
                        value={'FMS Machine'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                </Grid2>
                <Grid2
                    size={12}
                    display={'flex'}
                    justifyContent={'space-between'}
                    columns={4}
                    columnGap={theme.spacing(1.5)}
                    sx={{ mb: theme.spacing(1.5) }}
                >
                    <TextField
                        label={'Mode ID'}
                        defaultValue={'8765'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Local Build'}
                        value={'12345'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                            },
                            ['& ::after']: {
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Server Name'}
                        value={'Secret'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                    <TextField
                        label={'Notes'}
                        value={'Mickey'}
                        slotProps={{
                            input: { disabled: true },
                        }}
                        sx={{
                            width: '100%',
                            mx: theme.spacing(1.5),
                            ['& .MuiInputBase-input.Mui-disabled']: {
                                WebkitTextFillColor: fmsColors.black80.value,
                                borderBottomStyle: 'solid',
                            },
                        }}
                    />
                </Grid2>
            </AccordionDetails>
        </Accordion>
    );
};

export default EmployerDetailsPlaceholder;
