import { OptionalString } from '../types/UtilityTypes';

/**
 * This is the function to use in order to check if a string exists or not.
 * It returns false for strings that are all whitespace,
 * empty string, null, or undefined.
 */
export function stringHasValue(value: OptionalString): value is string {
    return !!value && !isStringOnlyWhitespace(value);
}

/**
 * NOTE: This function returns false for non-string values such as `undefined` or `null`.
 * You might want `stringHasValue` instead.
 * @param {string} value
 * @returns True if the `value` is a string and if that string only contains whitespace. False otherwise.
 **/
export function isStringOnlyWhitespace(value: string): boolean {
    return /\S/.test(value) === false;
}

export function isOptionalString(value: unknown): value is OptionalString {
    return typeof value === 'string' || value === undefined || value === null;
}

export const alphabeticalSortComparator = (
    a: OptionalString,
    b: OptionalString,
): -1 | 0 | 1 => {
    const aBeforeB = -1;
    const tie = 0;
    const bBeforeA = 1;

    if (!stringHasValue(a)) {
        if (stringHasValue(b)) {
            bBeforeA;
        }
        return tie;
    }

    if (!stringHasValue(b)) {
        return aBeforeB;
    }

    const lowercasedA = a.toLowerCase();
    const lowercasedB = b.toLowerCase();

    if (lowercasedA < lowercasedB) {
        return aBeforeB;
    }

    if (lowercasedA > lowercasedB) {
        return bBeforeA;
    }

    return tie;
};
