import { useEffect, useRef } from 'react';

/**
 * Use this hook to access the value of a variable
 * as it was in the previous render of your component.
 */
export default function usePrevious<T>(value: T): T {
    const previousValueRef = useRef(undefined as T);

    // This effect will run after every render:
    useEffect(() => {
        previousValueRef.current = value;
    });

    return previousValueRef.current;
}
