import { FmRunSearchRequest, FmRunSearchResultList } from '../api/fmRun/fmRun';

export const mockFmRunRequest: FmRunSearchRequest = {
    tenantName: 'paperless',
    einc: 4,
};

export const mockFmRun: FmRunSearchResultList = {
    data: [
        {
            FmRunId: 123,
            HierId: 2,
            WeekendBill: '2024-10-20',
            GLPostDate: '2024-10-24T13:40:00',
            EINC: 104,
            CompanyFullName: 'An Example Company',
            DateCreated: '2024-10-24T13:34:00',
            DateAbandoned: null,
            FmRunTypeId: 1,
            FmRunTypeDescription: 'Final Wire',
            DateRunStart: '2024-10-24T13:34:00',
            DateRunComplete: '2024-10-24T13:40:00',
            ItemCount: 4,
            SumOfItems: 20000.91,
        },
        {
            FmRunId: 124,
            HierId: 128,
            WeekendBill: '2024-10-20',
            GLPostDate: null,
            EINC: 108,
            CompanyFullName: 'A Second Example Company',
            DateCreated: '2024-10-24T14:24:00',
            DateAbandoned: null,
            FmRunTypeId: 0,
            FmRunTypeDescription: 'Ad Hoc/Initial Wire',
            DateRunStart: '2024-10-24T14:24:00',
            DateRunComplete: null,
            ItemCount: 0,
            SumOfItems: 0.0,
        },
        {
            FmRunId: 125,
            HierId: 128,
            WeekendBill: '2024-10-20',
            GLPostDate: null,
            EINC: 109,
            CompanyFullName: 'A Third Example Company',
            DateCreated: '2024-10-24T15:22:00',
            DateAbandoned: null,
            FmRunTypeId: 4,
            FmRunTypeDescription: 'Payroll Wire',
            DateRunStart: '2024-10-24T15:22:00',
            DateRunComplete: null,
            ItemCount: 0,
            SumOfItems: 0.0,
        },
    ],
    totalCount: 3,
};
