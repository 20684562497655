import EmployerResultList, { EmployerRequest } from '../api/employer';

export const mockEmployerRequest: EmployerRequest = {
    tenantName: 'paperless',
};

export const mockEmployerResultList: EmployerResultList = [
    {
        einc: 123,
        companyFullName: 'Big League Staffing Co',
        tenantName: 'paperless',
    },
    {
        einc: 124,
        companyFullName: 'Little League Staffing Co',
        tenantName: 'paperless',
    },
    {
        einc: 125,
        companyFullName: 'Tiny League Staffing Co',
        tenantName: 'paperless',
    },
    {
        einc: 126,
        companyFullName: 'Wimpy League Staffing Co',
        tenantName: 'paperless',
    },
];
