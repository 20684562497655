import { ClientTypeList } from '../api/clientType';

export const mockClientTypeList: ClientTypeList = [
    {
        ClientTypeId: 1,
        ClientTypeName: 'Tax Only',
        ClientTypeDescription: 'Client only receives tax processing from LOP.',
    },
    {
        ClientTypeId: 2,
        ClientTypeName: 'Tax & Garnishments',
        ClientTypeDescription:
            'Client receives tax processing and garnishment filing from LOP',
    },
    {
        ClientTypeId: 3,
        ClientTypeName: 'Processing',
        ClientTypeDescription:
            'Client does not receive direct funding, but LOP processes their payroll',
    },
    {
        ClientTypeId: 4,
        ClientTypeName: 'Funding',
        ClientTypeDescription: 'Client receives direct funding from LOP',
    },
    {
        ClientTypeId: 5,
        ClientTypeName: 'Employer of Record',
        ClientTypeDescription:
            'Also known as "TMS". Client operates under the Tempworks Management Services name.',
    },
    {
        ClientTypeId: 6,
        ClientTypeName: 'Software Only',
        ClientTypeDescription: 'Client does not receive any services from LOP.',
    },
];
