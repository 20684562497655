import isArray from 'lodash/isArray';

import { ValidationMessage } from '../../../utils/formValidationUtils';

type FormattedValidationMessageProps = {
    validationMessage: ValidationMessage;
};

const FormattedValidationMessage = ({
    validationMessage,
}: FormattedValidationMessageProps) => {
    if (isArray(validationMessage)) {
        return (
            <>
                {validationMessage.map((message) => (
                    <div key={message}>{message}</div>
                ))}
            </>
        );
    }

    return <>{validationMessage}</>;
};

export default FormattedValidationMessage;
