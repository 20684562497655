import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { DataGrid } from '@mui/x-data-grid';
import VerificationRunDetailResult from '../../../../../api/fmRun/verificationRunDetailResult';
import { BooleanCircleIcon } from '../../../../common/assets/BooleanCircleIcon';
import { OptionalBoolean } from '../../../../../types/UtilityTypes';

export type VerificationDetailDataGridProps = {
    isLoading: boolean;
    verificationResultList: VerificationRunDetailResult | null;
    successfullyOverridden?: OptionalBoolean;
};
export const VerificationDetailDataGrid = (
    props: VerificationDetailDataGridProps,
) => {
    const { isLoading, verificationResultList, successfullyOverridden } = props;

    const resultRows = useMemo(() => {
        return (
            verificationResultList?.map((runDetail) => ({
                ...runDetail,
                id: runDetail.FmVerificationRunId,
            })) ?? []
        );
    }, [verificationResultList]);

    const columns = useMemo(
        (): GridColDef<GridValidRowModel>[] => [
            {
                field: 'FmVerificationRunId',
                headerName: 'Verification ID',
                type: 'string',
                flex: 0.75,
                headerAlign: 'left',
                align: 'left',
            },
            {
                field: 'IsVerified',
                headerName: 'Is Verified',
                type: 'boolean',
                flex: 0.75,
                headerAlign: 'left',
                align: 'left',
                renderCell: (cell) => {
                    const booleanValue = cell.value as boolean;

                    return <BooleanCircleIcon booleanValue={booleanValue} />;
                },
            },
            {
                field: 'DateVerified',
                headerName: 'Date Verified',
                type: 'string',
                flex: 1,
                headerAlign: 'left',
                align: 'left',
            },
            {
                field: 'IsOverridden',
                headerName: 'Is Overridden',
                type: 'boolean',
                flex: 0.75,
                headerAlign: 'left',
                align: 'left',
                renderCell: (cell) => {
                    const booleanValue = cell.value;
                    //If the result of overriding is successful, we'll just display that it's overridden without calling the API for the actual value
                    return (
                        <BooleanCircleIcon
                            booleanValue={
                                successfullyOverridden
                                    ? successfullyOverridden
                                    : booleanValue
                            }
                        />
                    );
                },
            },
            {
                field: 'OverriddenBy',
                headerName: 'Overridden By',
                type: 'string',
                flex: 0.9,
                headerAlign: 'left',
                align: 'left',
            },
            {
                field: 'OverrideAllowed',
                headerName: 'Override Allowed',
                type: 'boolean',
                flex: 0.75,
                headerAlign: 'left',
                align: 'left',
                renderCell: (cell) => {
                    const booleanValue = cell.value as boolean;

                    return <BooleanCircleIcon booleanValue={booleanValue} />;
                },
            },
            {
                field: 'Description',
                headerName: 'Description',
                type: 'string',
                flex: 2,
                headerAlign: 'left',
                align: 'left',
            },
        ],
        [resultRows, successfullyOverridden],
    );

    return (
        <DataGrid
            autosizeOnMount
            columns={columns}
            loading={isLoading}
            rows={resultRows}
            hideFooter
            disableColumnMenu
        />
    );
};
