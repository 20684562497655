/* eslint-disable react/prop-types */
import DropdownField, {
    DropdownFieldProps,
} from '../../components/common/form/formControls/DropdownField';
import { Merge, OptionalNumber } from '../../types/UtilityTypes';
import { ReactElement, useMemo, useState, KeyboardEvent } from 'react';
import { FieldState } from '../../utils/formStateUtils';
import FormattedValidationMessage from '../../components/common/form/FormattedValidationMessage';
import InlineLoadingErrorMessage from '../InlineLoadingErrorMessage';

import { ClientType, ClientTypeList } from '../../api/clientType';
import { mockClientTypeList } from '../../mockData/mockClientTypeList';

type ClientTypeDropdownProps = Merge<
    Omit<
        DropdownFieldProps<ClientType, OptionalNumber, false>,
        'options' | 'multiple'
    >,
    {
        /**
         * If you provide the `fieldState` for a field in your form, this component
         * will use it to set default props for the underlying `DropdownField` components.
         */
        fieldState?: FieldState<OptionalNumber>;
        container?: ReactElement;
    }
>;

const mapFieldStateToProps = (
    allProps: ClientTypeDropdownProps,
): ClientTypeDropdownProps => {
    const { fieldState, ...props } = allProps;
    if (!fieldState) return props;

    return {
        label: fieldState.label,
        name: fieldState.name,
        value: fieldState.value,
        onChange: (newValue) => {
            fieldState.setValue(newValue);
        },
        required: fieldState.required,
        error: fieldState.isNotValid,
        helperText: (
            <FormattedValidationMessage
                validationMessage={fieldState.validationMessage}
            />
        ),
        ...props,
        disabled: fieldState.formIsSubmitting || props.disabled,
    };
};

const ClientTypeDropdown = (_props: ClientTypeDropdownProps) => {
    const props = _props.fieldState ? mapFieldStateToProps(_props) : _props;

    const {
        container,
        value: ClientTypeId,
        onChange,
        ...propsForClientTypeDropdown
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [loadingErrorMessage, setErrorMessage] = useState(
        null as string | null,
    );
    const [openState, setOpenState] = useState(false);
    /* We're tracking the highlighted option so that can be set as the value when the user hits the right-arrow key */
    const [highlightedOption, setHighlightedOption] =
        useState<OptionalNumber>(null);

    const [clientTypes, setClientTypes] = useState<ClientTypeList | null>(null);
    useMemo(async () => {
        setIsLoading(true);
        const response =
            //await getClientTypeSearchResults(requestSettings);
            mockClientTypeList;
        /*if (!response.isSuccess) {
            setErrorMessage(response.errorMessage);
        } else {*/
        const ClientTypes = response.sort((a, b) => {
            const nameA = a.ClientTypeName.toUpperCase(); // ignore upper and lowercase
            const nameB = b.ClientTypeName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        }) as ClientTypeList | null;
        setClientTypes(ClientTypes);
        //}
    }, []);

    const setFieldValue = (newClientTypeId: OptionalNumber) => {
        onChange && onChange(newClientTypeId);
    };

    /* 
        In order to reduce the amount of time users need to use their mouse, 
        we're setting a keyboard shortcut to set the current highlighted option as the value 
        and then close the drop down
    */
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'ArrowRight' && openState == true) {
            setFieldValue(highlightedOption);
            setOpenState(false);
        }
    };

    const handleSelectOpen = () => {
        setOpenState(true);
    };
    const handleSelectClose = () => {
        setOpenState(false);
    };

    return (
        <DropdownField
            getOptionValue={(ClientType) => ClientType?.ClientTypeId}
            getOptionLabel={(ClientType) => {
                if (ClientType?.ClientTypeName.length > 1) {
                    return `${ClientType?.ClientTypeName}`;
                } else {
                    return '';
                }
            }}
            onKeyDown={handleKeyDown}
            loading={isLoading}
            {...propsForClientTypeDropdown}
            label={propsForClientTypeDropdown.label || 'ClientType'}
            options={clientTypes || []}
            value={ClientTypeId}
            open={openState}
            onHighlightChange={(event, option, reason) =>
                setHighlightedOption(option?.ClientTypeId)
            }
            onOpen={handleSelectOpen}
            onClose={handleSelectClose}
            onChange={(newClientTypeId) => {
                setFieldValue(newClientTypeId);
            }}
            {...(!!loadingErrorMessage && {
                error: true,
                helperText: (
                    <InlineLoadingErrorMessage
                        errorMessage={loadingErrorMessage}
                    />
                ),
            })}
        />
    );
};

export default ClientTypeDropdown;
