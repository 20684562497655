import { createTheme } from '@mui/material/styles';
import { fmsColors } from './colors';
import '@fontsource/inter';
import getThemeComponentDefaults from './themeComponentDefaults';

export function CreateFMSTheme() {
    const fmsTheme = createTheme({
        palette: {
            primary: {
                main: fmsColors.fmsPrimary100.value,
                light: fmsColors.fmsPrimary50.value,
                contrastText: '#FFF',
            },
            secondary: {
                main: fmsColors.fmsSecondary100.value,
            },
            text: {
                // The MUI default for secondary text (including form labels) is rgba(0, 0, 0, 0.54).
                // We're going to make is slightly darker for ADA compliance.
                // This will especially help with readability of form labels on "filled" inputs:
                secondary: 'rgba(0, 0, 0, 0.60)',
            },
            background: {
                default: fmsColors.fmsBackground.value,
            },
        },
        typography: {
            fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
        },
        spacing: (factor) => `${0.5 * factor}rem`,
    });

    return createTheme(fmsTheme, {
        components: getThemeComponentDefaults(fmsTheme),
    });
}
