import createSvgIcon from '@mui/material/utils/createSvgIcon';

export const CloseDrawerIcon = createSvgIcon(
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.20754 13.0147L5.32754 8.99486L9.20754 4.975C9.59754 4.57094 9.59754 3.91823 9.20754 3.51417C8.81754 3.11012 8.18754 3.11012 7.79754 3.51417L3.20754 8.26962C2.81754 8.67368 2.81754 9.32639 3.20754 9.73045L7.79754 14.4859C8.18754 14.89 8.81754 14.89 9.20754 14.4859C9.58754 14.0818 9.59754 13.4188 9.20754 13.0147Z"
            fill="#40434E"
            fillOpacity="0.8"
        />
        <path
            d="M14.7925 13.0147L10.9125 8.99486L14.7925 4.975C15.1825 4.57094 15.1825 3.91823 14.7925 3.51417C14.4025 3.11012 13.7725 3.11012 13.3825 3.51417L8.7925 8.26962C8.4025 8.67368 8.4025 9.32639 8.7925 9.73045L13.3825 14.4859C13.7725 14.89 14.4025 14.89 14.7925 14.4859C15.1725 14.0818 15.1825 13.4188 14.7925 13.0147Z"
            fill="#40434E"
            fillOpacity="0.8"
        />
    </svg>,
    'Close Drawer',
);

export default CloseDrawerIcon;
