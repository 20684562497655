/**
 * Allow multiple refs to refer to the same element
 * Source: https://github.com/facebook/react/issues/13029#issuecomment-445480443
 */
export const mergeRefs =
    (...refs) =>
    (ref) => {
        refs.forEach((resolvableRef) => {
            if (typeof resolvableRef === 'function') {
                resolvableRef(ref);
            } else if (resolvableRef) {
                resolvableRef.current = ref;
            }
        });
    };
