import createSvgIcon from '@mui/material/utils/createSvgIcon';

export const TenantSelectorIcon = createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 11V5.82998C15 5.29998 14.79 4.78998 14.41 4.41998L12.7 2.70998C12.31 2.31998 11.68 2.31998 11.29 2.70998L9.59 4.40998C9.21 4.78998 9 5.29998 9 5.82998V6.99998H5C3.9 6.99998 3 7.89998 3 8.99998V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 11.9 20.1 11 19 11H15ZM7 19H5V17H7V19ZM7 15H5V13H7V15ZM7 11H5V8.99998H7V11ZM13 19H11V17H13V19ZM13 15H11V13H13V15ZM13 11H11V8.99998H13V11ZM13 6.99998H11V4.99998H13V6.99998ZM19 19H17V17H19V19ZM19 15H17V13H19V15Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>,
    'Tenant Selector',
);
