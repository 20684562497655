import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridValidRowModel,
} from '@mui/x-data-grid';
import { OptionalNumber } from '../../../../types/UtilityTypes';
import { ClientSearchResultList } from '../../../../api/stagingClientList';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { stagingClientListAtom } from '../../../../store/fmRun/staging/stagingClientListAtom';

export type StagingClientListDataGridProps = {
    isLoading: boolean;
    clientSearchResultList: ClientSearchResultList | null;
};

export const StagingClientListDataGrid = (
    props: StagingClientListDataGridProps,
) => {
    const { isLoading, clientSearchResultList } = props;
    const [clientList, setClientList] = useRecoilState(stagingClientListAtom);

    const resultRows = useMemo(() => {
        return (
            clientSearchResultList?.data?.map((client) => ({
                ...client,
                id: client.EINC,
            })) ?? []
        );
    }, [clientSearchResultList?.data]);

    const columns = useMemo(
        (): GridColDef<GridValidRowModel>[] => [
            {
                field: 'EINC',
                headerName: 'EINC',
                type: 'string',
                flex: 0.5,
                headerAlign: 'left',
                align: 'left',
            },
            {
                field: 'ClientName',
                headerName: 'Client Name',
                type: 'string',
                flex: 1,
                headerAlign: 'left',
                align: 'left',
            },
            {
                field: 'ClientTypeName',
                headerName: 'Client Type',
                type: 'string',
                flex: 1,
                headerAlign: 'left',
                align: 'left',
            },
        ],
        [resultRows],
    );

    function handleChangeSelection(
        newRowSelectionModel: GridRowSelectionModel,
    ) {
        let selectedRows: Array<OptionalNumber> = [];
        newRowSelectionModel.forEach((selectedRow) => {
            const clientListSelection = resultRows.find(
                (row) => row.id === selectedRow[0],
            );
            selectedRows.push(clientListSelection?.EINC);
        });
        setClientList(selectedRows);
    }

    return (
        <DataGrid
            columns={columns}
            rows={resultRows}
            loading={isLoading}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
                handleChangeSelection(newRowSelectionModel);
            }}
            hideFooter
            disableColumnMenu
        />
    );
};
