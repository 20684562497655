import { useState, useCallback, useEffect } from 'react';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import some from 'lodash/some';
import { Dictionary, isEmpty } from 'lodash';
import { useBeforeUnload, unstable_useBlocker } from 'react-router-dom';

import usePrevious from '../hooks/usePrevious';
import {
    mergeValidationMessages,
    validateFieldMaxLength,
    validateFieldMaxValue,
    validateFieldMinLength,
    validateFieldMinValue,
    validateFieldWithRegex,
    validateRequiredField,
    ValidationMessage,
} from './formValidationUtils';
import { isOptionalNumber, isTruthyOrZero } from './numberUtils';
import { OptionalNumber, OptionalString } from '../types/UtilityTypes';
import { isOptionalString } from './stringUtils';

const DEFAULT_SUBMIT_FAILURE_MESSAGE =
    'The form submission failed for an unknown reason.';

export default function useFormState<TLiteralInitialValues extends FormValues>(
    formSettings: FormSettings<TLiteralInitialValues>,
): FormState<ToFormValues<TLiteralInitialValues>> {
    type TFormValues = ToFormValues<TLiteralInitialValues>;

    // Create the `initialFormValues` object. (I did useState instead of useRef in case we want to allow reinitializing in the future)
    const [initialFormValues] = useState(
        () =>
            mapValues(
                formSettings.fields,
                (f) => f.initialValue,
            ) as TFormValues,
    );

    // Declare the `formValues` state
    const [formValues, setFormValues] = useState(initialFormValues);
    const previousFormValues = usePrevious(formValues);

    // Declare the state to track whether the form is submitting
    const [formIsSubmitting, setFormIsSubmitting] = useState(false);

    // Declare the submissionResponse state variable
    const [submissionResponse, setSubmissionResponse] = useState(
        null as FormSubmissionResponse<TFormValues> | null,
    );
    const submitSucceeded = submissionResponse?.submitSucceeded || false;
    const submitFailed = !!submissionResponse && !submitSucceeded;

    // Declare the top-level form validation message, running the consumer's form `validate` function if it was supplied:
    let formValidationMessage = formSettings.validate
        ? formSettings.validate(formValues)
        : undefined;
    if (isEmpty(formValidationMessage)) {
        formValidationMessage = null;
    }

    // It's possible that form submission failed with just `{ submitSucceeded: false }` and no other validation message.
    // Let's set a default top-level formValidationMessage so that the user always has something explaining that the submission failed:
    let formSubmissionValidationMessage =
        submissionResponse?.formValidationMessage;
    if (submitFailed && !hasValidationMessages(submissionResponse)) {
        formSubmissionValidationMessage = DEFAULT_SUBMIT_FAILURE_MESSAGE;
    }

    // If the form submission provided a validation message for the form,
    // let's add that to the `formValidationMessage`:
    formValidationMessage = mergeValidationMessages(
        formValidationMessage,
        formSubmissionValidationMessage,
    );

    /**
     * Removes the submit validation message for a particular field
     * so that the user doesn't have to see that anymore.
     */
    const clearSubmissionValidation = useCallback(
        <FieldName extends keyof TFormValues>(fieldName: FieldName) => {
            if (
                !submissionResponse ||
                !hasValidationMessages(submissionResponse)
            ) {
                return;
            }

            const newSubmissionResponse = {
                ...submissionResponse,
                fieldValidationMessages: {
                    ...submissionResponse.fieldValidationMessages,
                    [fieldName]: undefined,
                },
            } as typeof submissionResponse;

            if (hasValidationMessages(newSubmissionResponse)) {
                setSubmissionResponse(newSubmissionResponse);
            }

            // After clearing the validationMessage for this field,
            // there are no more validation messages in this submission response.
            // Let's clear out the entire submission response
            // so that the form is not considered invalid anymore,
            // and the user can retry the submission:
            setSubmissionResponse(null);
        },
        [submissionResponse],
    );

    /**
     * Sets the value of the specified field.
     */
    const setFieldValue = useCallback(
        <FieldName extends keyof TFormValues>(
            fieldName: FieldName,
            newFieldValue: TFormValues[FieldName],
        ) => {
            // Set the field value:
            setFormValues((_formValues) => ({
                ..._formValues,
                [fieldName]: newFieldValue,
            }));
            // Now, clear the submission validation message for this field,
            // (if one exists), so that the user can attempt the submission again:
            clearSubmissionValidation(fieldName);
        },
        [clearSubmissionValidation],
    );

    // When any of the form values change, we will clear the top-level form validation message so the user can try the submission again:
    useEffect(() => {
        if (formValues !== previousFormValues) {
            clearSubmissionValidation('formValidationMessage');
        }
    }, [formValues, previousFormValues, clearSubmissionValidation]);

    // Now let's create the state for each individual field.
    const fields = mapValues(
        formSettings.fields,
        (fieldSettings, fieldName) => {
            const fieldValue = formValues?.[fieldName];
            const previousFieldValue = previousFormValues?.[fieldName];
            const initialFieldValue = initialFormValues?.[fieldName];

            // Now, let's validate the field.
            // We'll initialize the `fieldValidationMessage` to the `submissionResponse` validation message,
            // if it exists:
            let fieldValidationMessage = hasValidationMessages(
                submissionResponse,
            )
                ? submissionResponse.fieldValidationMessages?.[fieldName]
                : null;

            if (fieldSettings.required) {
                const validationMessage = validateRequiredField(
                    fieldValue,
                    fieldSettings.label,
                );
                fieldValidationMessage = mergeValidationMessages(
                    fieldValidationMessage,
                    validationMessage,
                );
            }

            if (
                isTruthyOrZero(fieldSettings.maxLength) &&
                isOptionalString(fieldValue)
            ) {
                const validationMessage = validateFieldMaxLength(
                    fieldValue,
                    fieldSettings.maxLength,
                    fieldSettings.label,
                );
                fieldValidationMessage = mergeValidationMessages(
                    fieldValidationMessage,
                    validationMessage,
                );
            }

            if (
                isTruthyOrZero(fieldSettings.minLength) &&
                isOptionalString(fieldValue)
            ) {
                const validationMessage = validateFieldMinLength(
                    fieldValue,
                    fieldSettings.minLength,
                    fieldSettings.label,
                );
                fieldValidationMessage = mergeValidationMessages(
                    fieldValidationMessage,
                    validationMessage,
                );
            }

            if (
                isTruthyOrZero(fieldSettings.maxValue) &&
                isOptionalNumber(fieldValue)
            ) {
                const validationMessage = validateFieldMaxValue(
                    fieldValue,
                    fieldSettings.maxValue,
                    fieldSettings.label,
                );
                fieldValidationMessage = mergeValidationMessages(
                    fieldValidationMessage,
                    validationMessage,
                );
            }

            if (
                isTruthyOrZero(fieldSettings.minValue) &&
                isOptionalNumber(fieldValue)
            ) {
                const validationMessage = validateFieldMinValue(
                    fieldValue,
                    fieldSettings.minValue,
                    fieldSettings.label,
                );
                fieldValidationMessage = mergeValidationMessages(
                    fieldValidationMessage,
                    validationMessage,
                );
            }

            if (fieldSettings.pattern && isOptionalString(fieldValue)) {
                const validationMessage = validateFieldWithRegex(
                    fieldValue,
                    fieldSettings.pattern,
                    fieldSettings.label,
                );
                fieldValidationMessage = mergeValidationMessages(
                    fieldValidationMessage,
                    validationMessage,
                );
            }

            // Run the custom validation function, if supplied:
            if (fieldSettings.validate) {
                const validationMessage = fieldSettings.validate(
                    fieldValue,
                    formValues,
                    fieldSettings.label,
                );
                fieldValidationMessage = mergeValidationMessages(
                    fieldValidationMessage,
                    validationMessage,
                );
            }

            if (isEmpty(fieldValidationMessage)) {
                fieldValidationMessage = null;
            }
            const isValid = !fieldValidationMessage;

            const isPristine = isEqual(fieldValue, initialFieldValue);

            return {
                value: fieldValue,
                setValue: (newFieldValue) => {
                    setFieldValue(fieldName, newFieldValue);
                },
                previousValue: previousFieldValue,
                initialValue: initialFieldValue,
                label: fieldSettings.label,
                name: fieldName,
                required: fieldSettings.required,
                isValid,
                isNotValid: !isValid,
                validationMessage: fieldValidationMessage,
                isPristine,
                isDirty: !isPristine,
                formIsSubmitting,
            } as FieldState<typeof fieldValue>;
        },
    ) as FieldsState<TFormValues>;

    // Some computed values:
    const formIsPristine = every(fields, (f) => f.isPristine);
    const formIsValid =
        every(fields, (f) => f.isValid) && !formValidationMessage;
    const canSubmitForm = formIsValid && !formIsSubmitting;

    // The consumer will call this function in order to submit their form:
    const submitForm = useCallback<SubmitForm<TFormValues>>(
        async (event) => {
            // If this submit function was provided to a <form> element like this: `<form onSubmit={formState.submitForm}>`,
            // Then the browser will reload the page after a form submission.
            // We don't want to do that in this Single Page Application.
            // `event.preventDefault()` keeps it from doing that.
            event?.preventDefault();

            if (!canSubmitForm) {
                console.error('This form cannot be submitted right now.', {
                    canSubmitForm,
                    formIsValid,
                    formIsSubmitting,
                });
                return;
            }

            setFormIsSubmitting(true);
            try {
                const onSubmit = formSettings.onSubmit; // Appease the linter: https://github.com/facebook/react/issues/15924#issuecomment-521253636
                const submissionResponse = await onSubmit(formValues);
                setSubmissionResponse(submissionResponse);
            } catch (e) {
                let validationMessage: ValidationMessage;
                if (e instanceof Error && !!e.message) {
                    validationMessage = `The form submission failed with the following error: "${e.message}"`;
                } else {
                    validationMessage = DEFAULT_SUBMIT_FAILURE_MESSAGE;
                }
                setSubmissionResponse({
                    formValidationMessage: validationMessage,
                });
            } finally {
                setFormIsSubmitting(false);
            }
        },
        [
            formSettings.onSubmit,
            formValues,
            formIsSubmitting,
            canSubmitForm,
            formIsValid,
        ],
    );

    const resetForm = useCallback(() => {
        setFormValues(initialFormValues);
    }, [initialFormValues]);

    // Return our `formState` to the consumer
    return {
        fields,
        formValues,
        setFormValues,
        setFieldValue,
        submitForm,
        initialFormValues,
        resetForm,
        formIsValid,
        formIsNotValid: !formIsValid,
        formValidationMessage,
        formIsPristine,
        formIsDirty: !formIsPristine,
        formIsSubmitting,
        canSubmitForm,
        canNotSubmitForm: !canSubmitForm,
        submitSucceeded,
        submitFailed,
    };
}

/**
 * Use this hook to prompt the user for confirmation before navigating away from this page in FMS, or closing the browser tab.
 * Navigation will be confirmed only when `shouldBlockLeaving` is `true`.
 * @param shouldBlockLeaving When `true`, the user will be prompted to confirm when navigating within FMS or closing the browser tab.
 * @param userPrompt Provide the message that prompts the user to confirm their navigation.
 * NOTE: Modern browsers ignore this message and provide their own predefined prompt when closing the browser window,
 * but your message will still be used when navigating within FMS.
 */
export const useNavigationBlock = (
    shouldBlockLeaving: boolean,
    userPrompt = 'You have unsaved data. Are you sure you would like to leave?',
) => {
    // Warn the user before closing the browser window:
    useBeforeUnload((event) => {
        if (shouldBlockLeaving) {
            if (event) {
                event.returnValue = userPrompt;
            }
            return userPrompt;
        }
    });

    // Warn the user on route changes in react-router (Navigating within FMS)
    // https://stackoverflow.com/a/75840128
    const blocker = unstable_useBlocker(shouldBlockLeaving);
    if (blocker.state === 'blocked') {
        if (window.confirm(userPrompt)) {
            blocker.proceed?.();
        } else {
            blocker.reset?.();
        }
    }

    useEffect(() => {
        if (blocker.state === 'blocked' && !shouldBlockLeaving) {
            blocker.reset?.();
        }
    }, [blocker, shouldBlockLeaving]);
};

/**
 * Checks the provided submissionResponse for validation messages.
 * Returns `true` if any property on the `submissionResponse` has a truthy value,
 * besides the `submitSucceeded` property.
 */
const hasValidationMessages = <TFormValues extends FormValues>(
    submissionResponse: FormSubmissionResponse<TFormValues> | null | undefined,
): submissionResponse is FailedFormSubmissionResponse<TFormValues> =>
    !!(submissionResponse as FailedFormSubmissionResponse<TFormValues>)
        ?.formValidationMessage &&
    some(
        (submissionResponse as FailedFormSubmissionResponse<TFormValues>)
            .formValidationMessage,
        (fieldValidationMessage) => !!fieldValidationMessage,
    );

export type FormFieldValue =
    | string
    | number
    | boolean
    | null
    | undefined
    | Array<FormFieldValue>
    | Dictionary<FormFieldValue>
    | unknown;

export type FormValues = Dictionary<FormFieldValue>;

type MakeStringsAndNumbersOptional<T> = {
    [K in keyof T]: T[K] extends string
        ? OptionalString
        : T[K] extends number
          ? OptionalNumber
          : T[K];
};

/**
 * TypeScript infers the form value type based on your `initialValue` parameters to `useFormState()`.
 * In the form values, it should be possible for numbers and strings to be null,
 * in case the user clears out a text field.
 * So let's make those optional automatically:
 */
type ToFormValues<TInitialValues> =
    MakeStringsAndNumbersOptional<TInitialValues>;

export type FailedFormSubmissionResponse<TFormValues extends FormValues> = {
    /**
     * Indicates whether this form submission succeeded or failed
     */
    submitSucceeded?: false;

    /**
     * The top-level validation message for the form as a result of a failed form submission.
     */
    formValidationMessage?: ValidationMessage;

    /**
     * Validation messages for particular fields as a result of a failed form submission
     */
    fieldValidationMessages?: {
        [FieldName in keyof TFormValues]?: ValidationMessage;
    };
};

export type FormSubmissionResponse<TFormValues extends FormValues> =
    | {
          /**
           * Indicates whether this form submission succeeded or failed
           */
          submitSucceeded: true;

          /**
           * Must not be specified when `submitSucceeded` is true.
           * The top-level validation message for the form as a result of a failed form submission.
           */
          formValidationMessage?: null | undefined;
      }
    | FailedFormSubmissionResponse<TFormValues>;

export type FormSubmitFunction<TFormValues extends FormValues> = (
    formValues: TFormValues,
) => Promise<FormSubmissionResponse<TFormValues>>;

export type FormSettings<TInitialValues extends FormValues> = {
    /**
     * Settings for each of the fields in this form.
     */
    fields: FieldsSettings<TInitialValues>;

    /**
     * This async function will be called when the user submits the form.
     * Inside this function, you can call a server endpoint to submit the data.
     * While this submission is happening, `formState.isSubmitting` will be `true`,
     * and `formState.canSubmit` will be `false`.
     *
     * If the submission fails and you want to display validation messages in the form,
     * you can return an object from this function with validation messages keyed by each field name.
     * Those messages will be added to the usual place: `formState.fields.myField.validationMessage`.
     * In order to display a top-level form validation message, you can put it on the
     * `formValidationMessage` property in the returned object, and it will be added to `formState.formValidationMessage`.
     */
    onSubmit: FormSubmitFunction<ToFormValues<TInitialValues>>;

    /**
     * A custom validation function for this form.
     * If you return a validation message, it represents a message for the entire form,
     * and should be displayed at the beginning of the form.
     * If you want to display validation messages next to individual fields, provide a function at `fields.myField.validate`.
     **/
    validate?: (formValues: ToFormValues<TInitialValues>) => ValidationMessage;
};

export type FieldsSettings<TInitialValues extends FormValues> = {
    [FieldName in keyof TInitialValues]: FieldSettings<
        ToFormValues<TInitialValues>[FieldName],
        ToFormValues<TInitialValues>
    >;
};

export type FieldSettings<
    TFieldValue extends FormFieldValue,
    TFormValues extends FormValues,
> = {
    /**
     * The label of the field to display in the UI.
     * It will also be used in validation messages for this field.
     **/
    label: string;

    /**
     * The initial value of the field in the form
     **/
    initialValue: TFieldValue;

    /**
     * Indicates whether the field value is required in the form.
     **/
    required?: boolean;

    /**
     * A non-negative integer. If provided, the field value will be validated as a string with this maxLength.
     **/
    maxLength?: number;

    /**
     * A non-negative integer. If provided, the field value will be validated as a string with this minLength.
     **/
    minLength?: number;

    /**
     * If provided, the property value will be validated as a number with this maximum value.
     **/
    maxValue?: number;

    /**
     * If provided, the property value will be validated as a number with this minimum value.
     **/
    minValue?: number;

    /**
     * A regular expression against which to test the field value in form validation.
     **/
    pattern?: RegExp;

    /**
     * A custom validation function for this field.
     * If you return undefined from this function (or don't return), the field is valid.
     * If you return a string error message, the field is invalid.
     **/
    validate?: (
        value: TFieldValue,
        formValues: TFormValues,
        fieldLabel: string,
    ) => ValidationMessage;
};

export type FieldState<TFieldValue extends FormFieldValue> = Readonly<{
    /**
     * The current value of this field
     **/
    value: TFieldValue;

    /**
     * Calling this function will set a new value to this field
     **/
    setValue: (newValue: TFieldValue) => void;

    /**
     * The value of this field from the previous render of the form component
     **/
    previousValue: TFieldValue;

    /**
     * The initial value of this field
     **/
    initialValue: TFieldValue;

    /**
     * The label of the field to display in the UI
     **/
    label: string;

    /**
     * The programmatic name of the form field
     */
    name: string;

    /**
     * Indicates if this field is required
     */
    required: boolean;

    /**
     * `true` if the value of this field passes validation
     **/
    isValid: boolean;

    /**
     * The opposite of `isValid`. `true` if the value of this field fails validation.
     **/
    isNotValid: boolean;

    /**
     * A message to show to the user. Populated if the value of this field does not pass validation, otherwise `undefined`.
     **/
    validationMessage: ValidationMessage;

    /**
     * `true` if the value of this field is the same as its initial value (measured by a deep equality check)
     **/
    isPristine: boolean;

    /**
     * `true` if the value of this field is different than its initial value (measured by a deep equality check)
     **/
    isDirty: boolean;

    /**
     * `true` if the form is currently submitting. Provided here on the `fieldState` for convenience, if you want to disable the input when the form is submitting.
     */
    formIsSubmitting: boolean;
}>;

export type FieldsState<TFormValues extends FormValues> = Readonly<{
    [FieldName in keyof TFormValues]-?: FieldState<TFormValues[FieldName]>;
}>;

type SubmitForm<TFormValues extends FormValues> = (
    event?: React.FormEvent<HTMLFormElement>,
) => Promise<void | FailedFormSubmissionResponse<TFormValues>>;

export type FormState<TFormValues extends FormValues> = Readonly<{
    /**
     * The current state of the fields in this form
     **/
    fields: FieldsState<TFormValues>;

    /**
     * The current form values
     **/
    formValues: TFormValues;

    /**
     * Call this function to set new values to the form
     **/
    setFormValues: (newFormValues: TFormValues) => void;

    /**
     * Call this function to set the value of a particular field in the form
     */
    setFieldValue: <FieldName extends keyof TFormValues>(
        fieldName: FieldName,
        newFieldValue: TFormValues[FieldName],
    ) => void;

    /**
     * Call this function to reset the form values to their initial values.
     */
    resetForm: () => void;

    /**
     * The initial values of the form
     **/
    initialFormValues: TFormValues;

    /**
     * `true` if the value every field in this form is valid, and the top-level form `validate` function returns `undefined`
     **/
    formIsValid: boolean;

    /**
     * `true` if at least one field in the form is invalid, or if the top-level form `validate` function returns a validation message
     **/
    formIsNotValid: boolean;

    /**
     * A top-level validation message to show the user. Populated if the form `validate` function returned a message, otherwise `undefined`.
     **/
    formValidationMessage: ValidationMessage;

    /**
     * `true` if the current form values are the same as the initial form values (measured by a deep equality check)
     **/
    formIsPristine: boolean;

    /**
     * `true` if the current form values are different than the initial form values (measured by a deep equality check)
     **/
    formIsDirty: boolean;

    /**
     * `true` if the Promise returned by your `onSubmit` funciton is still pending
     */
    formIsSubmitting: boolean;

    /**
     * `true` if `formState.formIsValid` is `true`, and `formState.formIsSubmitting` is `false`
     */
    canSubmitForm: boolean;

    /**
     * The opposite of `formState.canSubmitForm`. `true` if `formState.formIsNotValid` is `true` or `formState.formIsSubmitting` is `true`.
     */
    canNotSubmitForm: boolean;

    /**
     * Call this function to submit the form, like this: `formState.submitForm()`.
     * Or provide it as a prop to an HTML `<form>` element like this: `<form onSubmit={formState.submitForm}>`
     */
    submitForm: SubmitForm<TFormValues>;

    /**
     * True if the most recent form submission attempt returned `{ submitSucceeded: true }`.
     * This value is reset from `true` to `false` after any form values have changed.
     */
    submitSucceeded: boolean;

    /**
     * True if the most recent form submission attempt returned a validation message for one or more fields,
     * or if it returned a top-level `formValidationMessage`, or if it returned `{ submitSucceeded: false }`, or if the
     * promise returned from your `onSubmit` function was rejected.
     *
     * This value is reset from `false` to `true` after the values have changed for all fields with submission validation messages.
     */
    submitFailed: boolean;
}>;
