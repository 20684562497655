import { DOMAttributes } from 'react';
import { Box, BoxProps } from '@mui/system';

import { Merge, PartialBy } from '../../../types/UtilityTypes';
import { ValidationMessage } from '../../../utils/formValidationUtils';
import FormValidationMessage from './FormValidationMessage';

export type FormProps = Merge<
    PartialBy<BoxProps, 'component'>,
    {
        /**
         * A function which will be called when the form is submitted.
         * You will likely pass in `formState.submitForm`.
         */
        onSubmit: DOMAttributes<HTMLFormElement>['onSubmit'];
        /**
         * A top-level validation message for the form.
         * It will be displayed at the top of the form.
         * You will likely pass in `formState.formValidationMessage`.
         */
        validationMessage: ValidationMessage;
    }
>;

const Form = ({ children, validationMessage, ...propsForForm }: FormProps) => {
    return (
        <Box component="form" autoComplete="off" {...propsForForm}>
            <FormValidationMessage>{validationMessage}</FormValidationMessage>
            {children}
        </Box>
    );
};

export default Form;
