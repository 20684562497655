import {
    ChangeEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import {
    GridRowModel,
    GridValidRowModel,
} from '@mui/x-data-grid/models/gridRows';

import {
    DataGrid,
    GridRenderCellParams,
    useGridApiContext,
    useGridApiRef,
} from '@mui/x-data-grid';
import { fmRunVerificationStatusNames } from '../../../../../constants/fmRunVerificationStatusNames';
import { fmsColors } from '../../../../../styles/colors';
import {
    VerificationRunDetailLogMessageResult,
    VerificationRunDetailLogMessageResultList,
} from '../../../../../api/fmRun/verificationRunDetailLogMessage';
import { Alert, AlertProps, Chip, Snackbar, TextField } from '@mui/material';
import { stringHasValue } from '../../../../../utils/stringUtils';

export type VerificationDetailMessageLogDataGridProps = {
    isLoading: boolean;
    verificationRunDetailLogMessageResults: VerificationRunDetailLogMessageResultList | null;
    onSaveNote: (requiredNotesAreComplete: boolean) => void;
};

// TODO replace this with useMutation from @tanstack/react-query
const useFakeMutation = () => {
    return useCallback(
        (message: Partial<VerificationRunDetailLogMessageResult>) =>
            new Promise<Partial<VerificationRunDetailLogMessageResult>>(
                (resolve, reject) => {
                    setTimeout(() => {
                        resolve({
                            ...message,
                        });
                    }, 200);
                },
            ),
        [],
    );
};

export const VerificationDetailMessageLogDataGrid = (
    props: VerificationDetailMessageLogDataGridProps,
) => {
    const { isLoading, verificationRunDetailLogMessageResults, onSaveNote } =
        props;

    const gridApiRef = useGridApiRef();

    const resultRows = useMemo(() => {
        return (
            verificationRunDetailLogMessageResults?.data?.map((client) => ({
                ...client,
                id: client.fmVerificationRunMessageId,
            })) ?? []
        );
    }, [verificationRunDetailLogMessageResults?.data]);

    const mutateRow = useFakeMutation();

    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = useCallback(
        async (newRow: GridRowModel) => {
            // Make the HTTP request to save in the backend
            const response = await mutateRow(newRow);
            setSnackbar({
                children: 'Message successfully saved',
                severity: 'success',
            });
            return response;
        },
        [mutateRow],
    );

    // The purpose of this function is to relay to the parent tab
    // whether there is a failed message without a logged note,
    // which is part of determining whether the override button is disabled.
    // It should only run when the rows are first loaded and when a cell changes to/from edit state
    function countRowsRequiringNotes() {
        let rowsRequiringNotes: Array<string> = [];
        gridApiRef.current.getRowModels().forEach((row) => {
            if (
                row.logStatus.toLowerCase() ===
                    fmRunVerificationStatusNames.FAIL.toLowerCase() &&
                !stringHasValue(row.logMessageNote)
            ) {
                rowsRequiringNotes.push(row.id);
            }
        });
        onSaveNote(rowsRequiringNotes.length > 0);
    }

    useEffect(() => {
        countRowsRequiringNotes;
    }, [resultRows]);

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    function determineChipColor(cellValueToLowerCase: string) {
        switch (cellValueToLowerCase) {
            case fmRunVerificationStatusNames.PASS.toLowerCase():
                return fmsColors.successMuted.value;
            case fmRunVerificationStatusNames.FAIL.toLowerCase():
                return fmsColors.errorMuted.value;
            case fmRunVerificationStatusNames.WARNING.toLowerCase():
                return fmsColors.warningMuted.value;
            case fmRunVerificationStatusNames.SKIP.toLowerCase():
                return fmsColors.infoMuted.value;
            default:
                return fmsColors.successMuted.value;
        }
    }

    // This is a custom Textfield element in the Log Message Note column which allows quick editing.
    function RenderNoteField(params: GridRenderCellParams) {
        const { id, value, field, hasFocus } = params;

        // This holds the context of the grid/cell so that new values are maintained when the user types into the component.
        const messageNoteApiContext = useGridApiContext();

        // This maintains focus on the field while in edit mode.
        useLayoutEffect(() => {
            setTimeout(() => {
                if (hasFocus) {
                    ref.current?.focus();
                }
            }, 0);
        }, [hasFocus]);

        // This holds the ref of the Textfield component itself (rather than the cell, which is held by the context above)
        const ref = useRef<HTMLInputElement>();

        // This updates the value of the cell so it matches the value of the Textfield component
        const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            messageNoteApiContext.current.setEditCellValue({
                id,
                field,
                value: newValue,
            });
        };

        const placeholder: string =
            params.row.logStatus.toLowerCase() ===
            fmRunVerificationStatusNames.FAIL.toLowerCase()
                ? 'Add note (Required)'
                : 'Add note...';

        const parsedValue = !stringHasValue(value) ? '' : value;

        return (
            <TextField
                size="small"
                variant="outlined"
                placeholder={placeholder}
                fullWidth
                onChange={handleValueChange}
                value={parsedValue}
            />
        );
    }

    const columns = useMemo(
        (): GridColDef<GridValidRowModel>[] => [
            {
                field: 'fmVerificationRunMessageId',
                headerName: 'Message ID',
                type: 'string',
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
            },
            {
                field: 'messageDescription',
                headerName: 'Verification Description',
                type: 'string',
                flex: 0.75,
                headerAlign: 'left',
            },
            {
                field: 'logStatus',
                headerName: 'Log Status',
                type: 'string',
                flex: 0.5,
                headerAlign: 'left',
                renderCell: (cell) => {
                    const cellChipColor = determineChipColor(
                        cell.value.toLowerCase(),
                    );

                    return (
                        <Chip
                            variant="filled"
                            label={cell.value}
                            sx={{ backgroundColor: cellChipColor }}
                        />
                    );
                },
            },
            {
                field: 'nextStep',
                headerName: 'Next Step',
                type: 'string',
                flex: 2,
                headerAlign: 'left',
            },
            {
                field: 'logMessageNote',
                headerName: 'Log Message Note',
                type: 'string',
                flex: 1.25,
                headerAlign: 'left',
                editable: true,
                renderCell: (params: GridRenderCellParams) => (
                    <RenderNoteField {...params} />
                ),
            },
        ],
        [resultRows],
    );

    return (
        <>
            <DataGrid
                autosizeOnMount
                columns={columns}
                loading={isLoading}
                rows={resultRows}
                hideFooter
                disableColumnMenu
                getEstimatedRowHeight={() => 500}
                getRowHeight={() => 'auto'}
                sx={{
                    '& .MuiDataGrid-cell': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                        py: 1,
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                        py: 1.5,
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                        {
                            py: 2,
                        },
                }}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                onCellModesModelChange={countRowsRequiringNotes}
                apiRef={gridApiRef}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </>
    );
};
