import { Route, Routes, useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AppLayoutContainer from './components/AppLayoutContainer';
import Box from '@mui/material/Box';
import { RecoilRoot } from 'recoil';
import {
    AuthenticatedTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react';
import { AppProps } from './msal/msalTypes';
import { CustomNavigationClient } from './msal/utils/NavigationClient';
import { ReactNode, useEffect } from 'react';
import {
    AuthenticationResult,
    EventMessage,
    EventType,
} from '@azure/msal-browser';
import { Grid2, Typography } from '@mui/material';
import { BasicContainer } from './components/common/containers/BasicContainer';
import { SignInButton } from './components/api-authorization/SignInButton';

type AppBoxProps = {
    children: ReactNode;
};

// This component exists to add Msal event callbacks before we render the rest of the app

const AppBox = ({ children }: AppBoxProps) => {
    const { instance } = useMsal();

    // This ensures that the login event is registered and the active account is set.
    useEffect(() => {
        const callbackId = instance.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                instance.setActiveAccount(account);
            }
        });

        return () => {
            // This will be run on component unmount
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, []);

    return (
        <Box className="App">
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <BasicContainer
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 8,
                    }}
                >
                    <Typography variant="h3" align="center" sx={{ p: 8 }}>
                        Please log in
                    </Typography>
                    <Grid2
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <SignInButton />
                    </Grid2>
                </BasicContainer>
            </UnauthenticatedTemplate>
        </Box>
    );
};

const App = ({ pca }: AppProps) => {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={pca}>
            <AppBox>
                <RecoilRoot>
                    <AppLayoutContainer>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return (
                                    <Route
                                        key={index}
                                        {...rest}
                                        element={element}
                                    />
                                );
                            })}
                        </Routes>
                    </AppLayoutContainer>
                </RecoilRoot>
            </AppBox>
        </MsalProvider>
    );
};

export default App;
