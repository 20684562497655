import { FormHelperText, FormHelperTextProps, Collapse } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { isValidElement } from 'react';

import { Merge } from '../../../types/UtilityTypes';
import { ValidationMessage } from '../../../utils/formValidationUtils';
import FormattedValidationMessage from './FormattedValidationMessage';

/**
 * Use this component to render an error message in your form.
 */
const FormValidationMessage = <D extends React.ElementType, P>({
    children,
    ...formHelperTextProps
}: Merge<FormHelperTextProps<D, P>, { children?: ValidationMessage }>) => {
    const hasMessage = !!children && !isEmpty(children);

    return (
        <Collapse in={hasMessage}>
            <FormHelperText
                error={true}
                style={{ paddingBottom: 24 }}
                {...formHelperTextProps}
            >
                {isValidElement<ValidationMessage>(children) ? (
                    children
                ) : (
                    <FormattedValidationMessage validationMessage={children} />
                )}
            </FormHelperText>
        </Collapse>
    );
};

export default FormValidationMessage;
