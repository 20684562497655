import { TenantList } from '../types/Tenant';


export const mockTenantList: TenantList = [
    {
        tenantName: 'Paperless',
    },
    {
        tenantName: 'TwProcessing',
    },
    {
        tenantName: 'IKHofmann',
    },
];

export default mockTenantList;
