import { useMemo } from 'react';
import ThemeProvider, {
    ThemeProviderProps,
} from '@mui/material/styles/ThemeProvider';
import { CreateFMSTheme } from './fmsTheme';

// Omitting 'theme' from ThemeProviderProps because otherwise it asks for the default theme everytime it's used.
type FMSThemeProps = Omit<ThemeProviderProps, 'theme'>;

const FMSThemeProvider = (props: FMSThemeProps) => {
    const { ...rest } = props;

    const fmsTheme = useMemo(() => CreateFMSTheme(), []);

    return <ThemeProvider {...rest} theme={fmsTheme} />;
};

export default FMSThemeProvider;
