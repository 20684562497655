import Dialog from '@mui/material/Dialog';
import { useState, Fragment, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FmRunGrid } from './FmRunGrid';
import {
    FmRunSearchRequest,
    FmRunSearchResultList,
} from '../../../api/fmRun/fmRun';
import { mockFmRun, mockFmRunRequest } from '../../../mockData/mockFmRun';
import { OptionalNumber, OptionalString } from '../../../types/UtilityTypes';
import { BasicContainer } from '../../common/containers/BasicContainer';
import { useTheme } from '@mui/material/styles';
import { fmRunIdStateAtom } from '../../../store/fmRun/fmRunIdAtom';
import { useRecoilState } from 'recoil';

export type FmRunSelectDialogProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const FmRunSelectDialog = ({
    isOpen,
    handleClose,
}: FmRunSelectDialogProps) => {
    const [searchResults, setSearchResults] =
        useState<FmRunSearchResultList | null>(null);
    const [dataGridIsLoading, setDataGridIsLoading] = useState(false);

    // This just holds the current selected FmRunId while the dialog is open
    const [selectedDialogFmRunId, setSelectedDialogFmRunId] =
        useState<OptionalNumber>(null);

    // This holds whichever FmRunId was selected when the user clicked the submit button
    const [selectedRecoilFmRunId, setSelectedRecoilFmRunId] =
        useRecoilState(fmRunIdStateAtom);

    const theme = useTheme();

    const loadNewResults = useCallback(async (request: FmRunSearchRequest) => {
        setDataGridIsLoading(true);

        console.log('Retrieiving fake FmRun list...');

        const response = await getFakeFmRunList(request);

        console.log(`Results: ${response?.data[0].FmRunId}`);

        // TODO replace this in a future PR with something like:
        //      if(response.isSuccess)
        if (response !== null && response !== undefined) {
            setSearchResults(response);
        } else {
            alert('This will be an error message');
        }

        setDataGridIsLoading(false);
    }, []);

    // TODO replace this with an actual API call when the API is operational
    async function getFakeFmRunList(
        fmRunSearchRequest: FmRunSearchRequest,
    ): Promise<FmRunSearchResultList | null | undefined> {
        return new Promise(function (resolve, reject) {
            if (mockFmRun) {
                // Setting timeout to simulate loading from the API and display the built-in loading skeleton...
                setTimeout(() => {
                    resolve(mockFmRun as FmRunSearchResultList);
                }, 2000);
            } else {
                setTimeout(() => {
                    reject('You should have never seen this' as OptionalString);
                }, 2000);
            }
        });
    }

    // TODO replace this with useMutation from @tanstack/react-query
    useEffect(() => {
        loadNewResults(mockFmRunRequest);
    }, [mockFmRunRequest]);

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setSelectedRecoilFmRunId(selectedDialogFmRunId);
        handleClose();
    }

    return (
        <Fragment>
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        fontWeight: 500,
                        fontSize: 20,
                        lineHeight: theme.spacing(4),
                    }}
                >
                    Choose FM Run
                </DialogTitle>
                <DialogContent>
                    <FmRunGrid
                        isLoading={dataGridIsLoading}
                        fmRunSearchResultList={searchResults}
                        onFmRunSelectionChange={(fmRunId: OptionalNumber) =>
                            setSelectedDialogFmRunId(fmRunId)
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <BasicContainer
                        sx={{ display: 'flex', justifyContent: 'left' }}
                    >
                        <Button onClick={handleClose}>Cancel</Button>
                    </BasicContainer>
                    <BasicContainer
                        sx={{ display: 'flex', justifyContent: 'right' }}
                    >
                        <Button type="submit">Choose</Button>
                    </BasicContainer>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default FmRunSelectDialog;
