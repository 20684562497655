import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import type {
    EventMessage,
    AuthenticationResult,
    IPublicClientApplication,
} from '@azure/msal-browser';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
import { msalConfig } from './msal/authConfig';
import { OptionalString } from './types/UtilityTypes';

export const msalInstance: IPublicClientApplication =
    new PublicClientApplication(msalConfig);

msalInstance.initialize().then(async () => {
    const accounts = msalInstance.getAllAccounts();
    // Default to using the first account if no account is active on page load
    if (accounts.length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    //msalInstance.enableAccountStorageEvents();
});

const render = () => {
    const baseUrl = document
        .getElementsByTagName('base')[0]
        .getAttribute('href') as string;
    const rootElement = document.getElementById('root') as HTMLElement;
    const root = createRoot(rootElement);

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

    return root.render(
        <StrictMode>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <Suspense fallback={'Please wait...'} />
            </ErrorBoundary>
            <BrowserRouter basename={baseUrl}>
                <App pca={msalInstance} />
            </BrowserRouter>
        </StrictMode>,
    );
};

export default render;
