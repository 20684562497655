import { useState } from 'react';
import LoadingErrorDialog from './LoadingErrorDialog';

type InlineLoadingErrorMessage = {
    errorMessage: string;
};

const InlineLoadingErrorMessage = (props: InlineLoadingErrorMessage) => {
    const { errorMessage } = props;
    const [errorDialogIsOpen, setErrorDialogIsOpen] = useState(false);

    return (
        <>
            <a>
                Unable to load data. For troubleshooting,
                <a
                    style={{ color: 'blue' }}
                    onClick={() => {
                        setErrorDialogIsOpen(true);
                    }}
                >
                    &nbsp;VIEW ERROR.
                </a>
            </a>
            <LoadingErrorDialog
                isOpen={errorDialogIsOpen}
                handleClose={() => setErrorDialogIsOpen(false)}
                errorMessage={errorMessage}
            />
        </>
    );
};

export default InlineLoadingErrorMessage;
