import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Grid2,
    TextField,
} from '@mui/material';
import {
    FMSTabContent,
    FMSTabContentProps,
} from '../../../common/containers/FMSTabs';
import { fmRunIdStateAtom } from '../../../../store/fmRun/fmRunIdAtom';
import { useTheme } from '@mui/material/styles';
import { useRecoilState } from 'recoil';
import { BasicContainer } from '../../../common/containers/BasicContainer';
import { useState } from 'react';
import FmRunStartDialog from './FmRunStartDialog';
import { AlertMessage } from '../../../../types/AlertMessage';
import { fmsColors } from '../../../../styles/colors';
import EmployerDetailsPlaceholder from './EmployerDetailsPlaceholder';

export const FundingRunMainTabContent = (props: FMSTabContentProps) => {
    const { index, currentIndex } = props;
    const theme = useTheme();
    const [selectedFmRunId, setSelectedFmRunId] =
        useRecoilState(fmRunIdStateAtom);
    const [fmRunStartDialogOpen, setFmRunStartDialogOpen] =
        useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);

    return (
        <FMSTabContent index={index} currentIndex={currentIndex}>
            <FmRunStartDialog
                isOpen={fmRunStartDialogOpen}
                handleClose={() => {
                    setFmRunStartDialogOpen(false);
                    setAlertMessage({
                        MessageText: 'Successfully queued Fm Run',
                        MessageSeverity: 'success',
                    });
                }}
            />
            <BasicContainer sx={{ pr: 0.5 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        mb: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={() => setFmRunStartDialogOpen(true)}
                    >
                        Start FM Run
                    </Button>
                </Box>

                <Box>
                    {selectedFmRunId && (
                        <Accordion sx={{ width: '100%' }} expanded={true}>
                            <AccordionSummary>
                                Run Status - {selectedFmRunId}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid2
                                    size={12}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    columnGap={theme.spacing(1.5)}
                                >
                                    <TextField
                                        label={'Verification Status'}
                                        defaultValue={'Fail'}
                                        slotProps={{
                                            input: { disabled: true },
                                        }}
                                        sx={{
                                            width: '100%',
                                            mx: theme.spacing(1.5),
                                            ['& .MuiInputBase-input.Mui-disabled']:
                                                {
                                                    WebkitTextFillColor:
                                                        fmsColors.black80.value,
                                                    borderBottomStyle: 'solid',
                                                },
                                        }}
                                    />
                                    <TextField
                                        label={'Override Status'}
                                        value={'Success'}
                                        slotProps={{
                                            input: { disabled: true },
                                        }}
                                        sx={{
                                            width: '100%',
                                            mx: theme.spacing(1.5),
                                            ['& .MuiInputBase-input.Mui-disabled']:
                                                {
                                                    WebkitTextFillColor:
                                                        fmsColors.black80.value,
                                                },
                                            ['& ::after']: {
                                                borderBottomStyle: 'solid',
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={'Note'}
                                        value={
                                            'The WRI Average was lower due to customerId 1234'
                                        }
                                        slotProps={{
                                            input: { disabled: true },
                                        }}
                                        sx={{
                                            width: '100%',
                                            mx: theme.spacing(1.5),
                                            ['& .MuiInputBase-input.Mui-disabled']:
                                                {
                                                    WebkitTextFillColor:
                                                        fmsColors.black80.value,
                                                    borderBottomStyle: 'solid',
                                                },
                                        }}
                                    />
                                </Grid2>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
                <EmployerDetailsPlaceholder />
            </BasicContainer>
            {!!alertMessage && (
                <Alert
                    severity={
                        !!alertMessage.MessageSeverity
                            ? alertMessage.MessageSeverity
                            : 'info'
                    }
                    onClose={() => setAlertMessage(null)}
                >
                    {alertMessage.MessageText}
                </Alert>
            )}
        </FMSTabContent>
    );
};
