type FmRunVerificationStatusName = {
    [key: string]: string;
};

export const fmRunVerificationStatusNames: FmRunVerificationStatusName = {
    PASS: 'Pass',
    FAIL: 'Fail',
    WARNING: 'Warning',
    SKIP: 'Skip',
};
