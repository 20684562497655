import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LensIcon from '@mui/icons-material/Lens';
import HexagonIcon from '@mui/icons-material/Hexagon';
import List from '@mui/material/List';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton, {
    ListItemButtonProps,
} from '@mui/material/ListItemButton';
import MuiLink from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Grid, { Grid2Props } from '@mui/material/Grid2';
import Popover from '@mui/material/Popover';
import { NavLink as RouterLink, useMatch } from 'react-router-dom';
import ComponentErrorBoundary from './ComponentErrorBoundary';
import TenantDropdown from './dropdowns/TenantDropdown';
import { AppRoute, NavRoutes, LogoutRoute, ProfileRoute } from '../AppRoutes';
import { fmsColors } from '../styles/colors';
import { Merge, OptionalBoolean } from '../types/UtilityTypes';
import { TenantSelectorIcon } from './common/assets/TenantSelectorIcon';
import { createRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import CloseDrawerIcon from './common/assets/CloseDrawerIcon';
import { LogoutIcon } from './pages/Logout';
import { BasicContainer } from './common/containers/BasicContainer';

type NavItemProps = {
    route?: AppRoute;
    drawerOpen: boolean;
};

type NavMenuProps = {
    drawerOpen: boolean;
    toggleDrawer: () => void;
};

type NavChildProps = {
    drawerOpen: boolean;
};

const lowerActionTypographyProps: TypographyProps = {
    variant: 'caption',
    fontWeight: 600,
    fontSize: 16,
    mx: 1,
};

export const NavMenuGridItem = (props: Grid2Props) => {
    const { children, ...rest } = props;

    return (
        <Grid display="flex" size={12} justifyContent={'inherit'} {...rest}>
            {children}
        </Grid>
    );
};

export const NavMenuButton = ({
    drawerOpen,
    children,
    isTenantSelector,
    ...rest
}: Merge<
    Merge<ListItemButtonProps, NavChildProps>,
    { isTenantSelector?: OptionalBoolean }
>) => {
    const theme = useTheme();

    return (
        <ListItemButton
            {...rest}
            sx={{
                [theme.breakpoints.up('md')]: {
                    p: isTenantSelector ? theme.spacing(1) : theme.spacing(2),
                },
                [theme.breakpoints.down('md')]: {
                    p: isTenantSelector ? theme.spacing(1) : theme.spacing(2),
                },
                borderRadius: theme.spacing(1),
                alignContent: 'center',
                justifyContent: 'center',
                clipPath: drawerOpen
                    ? 'none'
                    : `inset(${theme.spacing(0.625)} round ${theme.spacing(1.25)})`,
                flexWrap: drawerOpen ? 'nowrap' : 'wrap',
            }}
        >
            {children}
        </ListItemButton>
    );
};

export const NavMenuItemText = ({
    drawerOpen,
    children,
}: Merge<NavChildProps, TypographyProps>) => {
    const theme = useTheme();

    return (
        <Typography
            sx={{
                fontSize: drawerOpen ? 'inherit' : 11,
                textAlign: drawerOpen ? 'inherit' : 'center !important',
                color: drawerOpen
                    ? fmsColors.black80.value
                    : fmsColors.black60.value,
                [theme.breakpoints.down('md')]: {
                    display: 'none',
                },
            }}
        >
            {children}
        </Typography>
    );
};

const NavIcon = ({ children }: ListItemIconProps) => {
    const theme = useTheme();

    return (
        <ListItemIcon
            sx={{
                [theme.breakpoints.down('lg')]: {
                    scale: '100%',
                },
                [theme.breakpoints.down('md')]: {
                    scale: '90%',
                },
                [theme.breakpoints.down('sm')]: {
                    scale: '80%',
                },
                [theme.breakpoints.down('xs')]: {
                    scale: '70%',
                },
            }}
        >
            {children}
        </ListItemIcon>
    );
};

export const NavItem = ({ route, drawerOpen }: NavItemProps) => {
    const routeMatch = useMatch(route?.path ?? '');
    const isCurrentPage = !!routeMatch;

    const theme = useTheme();

    function setLinkProps(route: AppRoute | undefined): Object {
        if (!route?.disabled) {
            if (!!route?.element) {
                return {
                    to: route?.path,
                    component: RouterLink,
                };
            } else {
                return {
                    href: route?.path,
                };
            }
        }
        return { underline: 'none' };
    }

    const linkProps = setLinkProps(route);

    return (
        <NavMenuGridItem>
            <MuiLink underline="hover" {...linkProps} sx={{ width: '100%' }}>
                <NavMenuButton
                    disabled={route?.disabled}
                    selected={isCurrentPage}
                    drawerOpen={drawerOpen}
                >
                    {route?.iconComponent && (
                        <NavIcon>{route?.iconComponent}</NavIcon>
                    )}
                    {!route?.iconComponent && (
                        <NavIcon>
                            <HexagonIcon />
                        </NavIcon>
                    )}
                    <ListItemText
                        primary={
                            <NavMenuItemText drawerOpen={drawerOpen}>
                                {drawerOpen
                                    ? route?.displayFullName
                                    : route?.displayShortName.toUpperCase()}
                            </NavMenuItemText>
                        }
                        sx={{
                            [theme.breakpoints.down('md')]: {
                                display: 'none',
                            },
                        }}
                    />
                </NavMenuButton>
            </MuiLink>
        </NavMenuGridItem>
    );
};

const NavMenu = ({ drawerOpen, toggleDrawer }: NavMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const divRef = createRef<HTMLDivElement>();

    const handleClick = () => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(divRef.current);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const theme = useTheme();

    const popoverOpen = Boolean(anchorEl);
    const id = popoverOpen ? 'tenant-list-popover' : undefined;

    return (
        <ComponentErrorBoundary>
            <Box
                sx={{
                    px: drawerOpen ? theme.spacing(3) : theme.spacing(2),
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: '100vh',
                    display: 'flex',
                    flexFlow: 'column',
                }}
            >
                <Box sx={{ flex: '0 1 auto' }}>
                    <NavMenuGridItem sx={{ justifyContent: 'center' }}>
                        {/*This button is just a placeholder while UX decides what to put here*/}

                        <IconButton
                            disabled
                            color={'primary'}
                            sx={{
                                width: theme.spacing(5),
                                mt: theme.spacing(1.5),
                                mb: theme.spacing(1.5),
                                [theme.breakpoints.down('lg')]: {
                                    scale: '100%',
                                },
                                [theme.breakpoints.down('md')]: {
                                    scale: '90%',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    scale: '80%',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    scale: '70%',
                                },
                            }}
                        >
                            <LensIcon
                                sx={{
                                    width: drawerOpen
                                        ? theme.spacing(8)
                                        : theme.spacing(6.5),
                                    height: theme.spacing(10),
                                    fill: fmsColors.fmsPrimary100.value,
                                }}
                            />
                        </IconButton>
                    </NavMenuGridItem>

                    <NavMenuGridItem>
                        {drawerOpen && <TenantDropdown />}
                        {!drawerOpen && (
                            <>
                                <NavMenuButton
                                    drawerOpen={drawerOpen}
                                    onClick={handleClick}
                                    isTenantSelector
                                >
                                    <NavIcon>
                                        <TenantSelectorIcon />
                                    </NavIcon>
                                    <ListItemText
                                        primary={
                                            <NavMenuItemText
                                                drawerOpen={drawerOpen}
                                            >
                                                TENANT
                                            </NavMenuItemText>
                                        }
                                    />
                                </NavMenuButton>
                                <div ref={divRef} />
                                <Popover
                                    id={id}
                                    open={popoverOpen}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <BasicContainer
                                        sx={{
                                            padding: theme.spacing(0.5),
                                            margin: theme.spacing(0.5),
                                        }}
                                    >
                                        <TenantDropdown
                                            sx={{
                                                width: theme.spacing(24),
                                            }}
                                        />
                                    </BasicContainer>
                                </Popover>
                            </>
                        )}
                    </NavMenuGridItem>
                    <Divider
                        variant="middle"
                        sx={{
                            my: drawerOpen
                                ? theme.spacing(2)
                                : theme.spacing(1),
                            mx: 0,
                            borderBottomWidth: 2,
                            width: '100%',
                        }}
                        flexItem
                    />
                </Box>
                <Box
                    sx={{
                        top: 0,
                        flex: '0 1 auto',
                    }}
                >
                    <List
                        component="nav"
                        sx={{
                            position: 'relative',
                        }}
                    >
                        {NavRoutes.map((route) => (
                            <NavItem
                                key={route?.path}
                                route={route}
                                drawerOpen={drawerOpen}
                            ></NavItem>
                        ))}
                    </List>
                </Box>

                <Box
                    sx={{
                        flex: '1 1 auto',
                    }}
                />
                <Box sx={{ flex: '0 1 auto' }}>
                    <Divider
                        variant={'fullWidth'}
                        flexItem
                        sx={{
                            flexDirection: 'row',
                            mb: theme.spacing(4),
                            borderBottomWidth: 2,
                        }}
                    />
                    <Grid
                        container
                        size={12}
                        flex={1}
                        flexDirection={'column'}
                        justifyContent={drawerOpen ? 'left' : 'center'}
                        alignContent={drawerOpen ? 'left' : 'center'}
                        sx={{ px: theme.spacing(1), mb: theme.spacing(4) }}
                    >
                        <NavMenuGridItem
                            display="flex"
                            size={12}
                            justifyContent={'inherit'}
                        >
                            <IconButton
                                size="small"
                                onClick={toggleDrawer}
                                sx={{
                                    scale: '82%',
                                    borderRadius: theme.spacing(1),
                                    transform: drawerOpen
                                        ? 'none'
                                        : 'rotateY(180deg)',
                                }}
                            >
                                <CloseDrawerIcon />
                                {drawerOpen && (
                                    <Typography {...lowerActionTypographyProps}>
                                        CLOSE
                                    </Typography>
                                )}
                            </IconButton>
                        </NavMenuGridItem>
                        {/*Empty grid for easier spacing*/}
                        <Box sx={{ my: theme.spacing(3) }} />
                        <NavMenuGridItem
                            display="flex"
                            size={12}
                            justifyContent={'inherit'}
                        >
                            <MuiLink href={LogoutRoute.path}>
                                <IconButton
                                    size="small"
                                    sx={{
                                        scale: '82%',
                                        borderRadius: theme.spacing(1),
                                    }}
                                >
                                    <LogoutIcon />
                                    {drawerOpen && (
                                        <Typography
                                            {...lowerActionTypographyProps}
                                        >
                                            LOGOUT
                                        </Typography>
                                    )}
                                </IconButton>
                            </MuiLink>
                        </NavMenuGridItem>
                    </Grid>
                </Box>
            </Box>
        </ComponentErrorBoundary>
    );
};

export default NavMenu;
