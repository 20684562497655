import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Link as RouterLink } from 'react-router-dom';
import { createSvgIcon } from '@mui/material/utils';
import WelcomeName from '../api-authorization/WelcomeName';
import { BasicContainer } from '../common/containers/BasicContainer';
import { useTheme } from '@mui/material';

export const HomeIcon = createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19 7H17V9H19V7Z" fill="black" fillOpacity="0.54" />
        <path d="M19 11H17V13H19V11Z" fill="black" fillOpacity="0.54" />
        <path d="M19 15H17V17H19V15Z" fill="black" fillOpacity="0.54" />
        <path
            d="M1 11V21H7V16H9V21H15V11L8 6L1 11ZM13 19H11V14H5V19H3V12L8 8.5L13 12V19Z"
            fill="black"
            fillOpacity="0.54"
        />
        <path
            d="M10 3V4.97L12 6.4V5H21V19H17V21H23V3H10Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>,
    'Home',
);

export function Home() {
    const theme = useTheme();

    return (
        <BasicContainer>
            <BasicContainer
                sx={{
                    p: theme.spacing(4),
                    display: 'flex',
                }}
            >
                <WelcomeName />
            </BasicContainer>
            <BasicContainer
                sx={{
                    p: theme.spacing(4),
                    display: 'flex',
                }}
            >
                <Button
                    component={RouterLink}
                    to="/Profile"
                    variant="contained"
                    color="primary"
                >
                    Request Profile Information
                </Button>
            </BasicContainer>
        </BasicContainer>
    );
}
