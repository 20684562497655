/* eslint-disable react/prop-types */
import MuiTextField from '@mui/material/TextField';
import { forwardRef } from 'react';
import { Merge, PropsType } from '../../../../types/UtilityTypes';
import { FieldState } from '../../../../utils/formStateUtils';
import FormattedValidationMessage from '../FormattedValidationMessage';

export type TextFieldProps = Merge<
    PropsType<typeof MuiTextField>,
    {
        /**
         * If you provide the `fieldState` for a field in your form, this component
         * will use it to set default props for the underlying Material UI TextField.
         */
        fieldState?: FieldState<unknown>;
        /**
         * If `true`, leading and trailing whitespace will be trimmed from the field value
         * when the field is blurred, so that users can't submit whitespace to the server.
         * Defaults to `true`.
         */
        trimOnBlur?: boolean;
    }
>;

/**
 * A wrapper around the Material UI `TextField` component.
 *
 * For the simplest usage, pass in the `fieldState` for a field in your form.
 * This component will use it to set default props for the underlying
 * Material UI `TextField` component so that it reflects the state of your field.
 * Feel free to override or expand upon those defaults by passing additional props to this component.
 *
 * For other usage, see the documentation for the Material UI `TextField`:
 * - [Examples](https://mui.com/material-ui/react-text-field/)
 * - [Description of props you can pass](https://mui.com/material-ui/api/text-field/)
 */
const TextField = (
    _props: TextFieldProps,
    ref: React.ForwardedRef<HTMLDivElement>,
) => {
    const props = _props.fieldState ? mapFieldStateToProps(_props) : _props;

    const trimOnBlur = props.trimOnBlur !== false; // Default to `true`

    return (
        <MuiTextField
            // Let's do `fullWidth` by default,
            // which should encourage people to use a layout system for input sizing:
            fullWidth={true}
            {...props}
            onBlur={(event) => {
                // Trim leading and trailing whitespace from the field value
                // when the field is blurred, so that users can't submit whitespace to the server:
                if (trimOnBlur && props.onChange) {
                    event.currentTarget.value =
                        event.currentTarget.value?.trim();
                    props.onChange(event);
                }

                // Call the consumer's blur handler, if they passed one in:
                if (props.onBlur) {
                    return props.onBlur(event);
                }
            }}
            ref={ref}
        />
    );
};

/**
 * If the consumer has provided a `fieldState` variable from their form (See `formUtils.ts`),
 * this function will map it to the correct props for this `TextField` component.
 */
const mapFieldStateToProps = (allProps: TextFieldProps): TextFieldProps => {
    const { fieldState, ...props } = allProps;
    if (!fieldState) return props;

    return {
        label: fieldState.label,
        name: fieldState.name,
        value: fieldState.value,
        onChange: (event) => {
            fieldState.setValue(event.target.value);
        },
        required: fieldState.required,
        error: fieldState.isNotValid,
        helperText: (
            <FormattedValidationMessage
                validationMessage={fieldState.validationMessage}
            />
        ),
        ...props,
        disabled: fieldState.formIsSubmitting || props.disabled,
    };
};

export default forwardRef(TextField);
