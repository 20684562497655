import createSvgIcon from '@mui/material/utils/createSvgIcon';

export const LogoutIcon = createSvgIcon(
    <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.75 3.25L10.6925 4.3075L12.6275 6.25H5V7.75H12.6275L10.6925 9.685L11.75 10.75L15.5 7L11.75 3.25ZM2 1.75H8V0.25H2C1.175 0.25 0.5 0.925 0.5 1.75V12.25C0.5 13.075 1.175 13.75 2 13.75H8V12.25H2V1.75Z"
            fill="#40434E"
            fillOpacity="0.8"
        />
    </svg>,
    'Log Out',
);
