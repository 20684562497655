import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
} from '@mui/material';

type LoadingErrorDialogProp = {
    isOpen: boolean;
    handleClose(): void;
    errorMessage: string;
};

const LoadingErrorDialog = (props: LoadingErrorDialogProp) => {
    const { isOpen, errorMessage, handleClose } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                handleClose();
            }}
        >
            <DialogTitle>Failed to Load Data</DialogTitle>
            <DialogContent>
                <FormHelperText error>{errorMessage}</FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    variant="contained"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default LoadingErrorDialog;
