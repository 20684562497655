import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import { loginRequest } from '../../msal/authConfig';

export const SignInButton = () => {
    const { instance, accounts } = useMsal();

    const handleLogin = async (loginType: string) => {
        if (accounts.length === 0) {
            if (loginType === 'popup') {
                await instance.loginPopup(loginRequest);
            } else if (loginType === 'redirect') {
                await instance.loginRedirect(loginRequest);
            }
        }
    };

    return (
        <div>
            <Button
                onClick={() => handleLogin('redirect')}
                color="inherit"
                variant="contained"
            >
                Log In
            </Button>
        </div>
    );
};
