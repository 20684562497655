import { NonzeroFalsy, OptionalNumber } from '../types/UtilityTypes';

/**
 * Zero is falsy, but often a valid value.
 * This utility exists to reinforce the idea that we often need to
 * make a special consideration for zero when we check for the existence of a value.
 */
export function isTruthyOrZero<T>(thing: T | NonzeroFalsy): thing is T {
    return !!thing || thing === 0;
}

export function isOptionalNumber(value: unknown): value is OptionalNumber {
    return typeof value === 'number' || value === undefined || value === null;
}
