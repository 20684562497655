import Dialog from '@mui/material/Dialog';
import { useState, Fragment, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRecoilState } from 'recoil';
import useFormState from '../../../../utils/formStateUtils';
import { useTheme } from '@mui/material/styles';
import { OptionalNumber, OptionalString } from '../../../../types/UtilityTypes';
import { BasicContainer } from '../../../common/containers/BasicContainer';
import DropdownField from '../../../common/form/formControls/DropdownField';
import FmRunTypeDropdown from '../../../dropdowns/FmRunTypeDropdown';
import { Box } from '@mui/material';
import EmployerDropdown from '../../../dropdowns/EmployerDropdown';
import WeekendBillDropdown from '../../../dropdowns/WeekendBillDropdown';
import FmPlanDropdown from '../../../dropdowns/FmPlanDropdown';

export type FmRunStartDialogProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export default function FmRunStartDialog({
    isOpen,
    handleClose,
}: FmRunStartDialogProps) {
    const theme = useTheme();

    const formState = useFormState({
        fields: {
            einc: {
                label: 'Client',
                initialValue: null as OptionalNumber,
                required: true,
            },
            fmRunTypeId: {
                label: 'Run Type',
                initialValue: null as OptionalNumber,
                required: true,
            },
            weekendBill: {
                label: 'Weekend Bill',
                initialValue: null as OptionalString,
                required: true,
            },
            fmPlanId: {
                label: 'Fm Plan',
                initialValue: null as OptionalNumber,
                required: false,
            },
        },
        onSubmit: async () => {
            return { submitSucceeded: true };
        },
    });

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        handleClose();
    }

    return (
        <Fragment>
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        fontWeight: 500,
                        fontSize: 20,
                        lineHeight: theme.spacing(4),
                        p: theme.spacing(3),
                    }}
                >
                    Start FM Run
                </DialogTitle>
                <DialogContent sx={{ p: theme.spacing(3) }}>
                    <Box>
                        <Box sx={{ mb: theme.spacing(2) }}>
                            <EmployerDropdown
                                fieldState={formState.fields.einc}
                            />
                        </Box>
                        <Box sx={{ mb: theme.spacing(2) }}>
                            <FmRunTypeDropdown
                                fieldState={formState.fields.fmRunTypeId}
                            />
                        </Box>
                        <Box sx={{ mb: theme.spacing(2) }}>
                            <WeekendBillDropdown
                                fieldState={formState.fields.weekendBill}
                            />
                        </Box>
                        <Box sx={{ mb: theme.spacing(2) }}>
                            <FmPlanDropdown
                                fieldState={formState.fields.fmPlanId}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <BasicContainer
                        sx={{ display: 'flex', justifyContent: 'left' }}
                    >
                        <Button onClick={handleClose}>Cancel</Button>
                    </BasicContainer>
                    <BasicContainer
                        sx={{ display: 'flex', justifyContent: 'right' }}
                    >
                        <Button type="submit">Process Run</Button>
                    </BasicContainer>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
