import concat from 'lodash/concat';
import { isTruthyOrZero } from './numberUtils';
import { isStringOnlyWhitespace } from './stringUtils';

export type ValidationMessage = undefined | null | string | Array<string>;

export function mergeValidationMessages(
    messageOne: ValidationMessage,
    messageTwo: ValidationMessage,
): ValidationMessage {
    if (!messageOne) {
        return messageTwo;
    }

    if (!messageTwo) {
        return messageOne;
    }

    const result = concat(messageOne, messageTwo);

    if (result.length === 1) {
        return result[0];
    }

    return result;
}

export function validateRequiredField(
    fieldValue: unknown,
    fieldLabel: string,
): ValidationMessage {
    if (fieldValue === 0) {
        return undefined; // Zero is valid
    }

    // Whitespace is invalid
    const isOnlyWhitespace =
        typeof fieldValue === 'string' && isStringOnlyWhitespace(fieldValue);

    if (!fieldValue || isOnlyWhitespace) {
        return `* required`;
    }
}

export function validateFieldMaxLength(
    fieldValue: string | null | undefined,
    maxLength: number,
    fieldLabel: string,
): ValidationMessage {
    if (fieldValue && fieldValue.length > maxLength) {
        return `${fieldLabel} cannot have more than ${maxLength} characters.`;
    }
}

export function validateFieldMinLength(
    fieldValue: string | null | undefined,
    minLength: number,
    fieldLabel: string,
): ValidationMessage {
    if (fieldValue && fieldValue.length < minLength) {
        return `${fieldLabel} must have at least ${minLength} characters.`;
    }
}

export function validateFieldMaxValue(
    fieldValue: number | null | undefined,
    maxValue: number,
    fieldLabel: string,
): ValidationMessage {
    if (isTruthyOrZero(fieldValue) && fieldValue > maxValue) {
        return `${fieldLabel} cannot be greater than ${maxValue}.`;
    }
}

export function validateFieldMinValue(
    fieldValue: number | null | undefined,
    minValue: number,
    fieldLabel: string,
): ValidationMessage {
    if (isTruthyOrZero(fieldValue) && fieldValue < minValue) {
        return `${fieldLabel} must be at least ${minValue}.`;
    }
}

export function validateFieldWithRegex(
    fieldValue: string | null | undefined,
    pattern: RegExp,
    fieldLabel: string,
): ValidationMessage {
    if (fieldValue && !pattern.test(fieldValue)) {
        return `${fieldLabel} has an invalid format.`;
    }
}
