import MuiSelect, {
    SelectProps as MuiSelectProps,
    SelectChangeEvent,
} from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, MenuItem } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { mockTenantList } from '../../mockData/mockTenantList';
import { TenantList } from '../../types/Tenant';
import { useRecoilState } from 'recoil';
import { tenantStateAtom } from '../../store/tenant/tenantAtom';
import { ExpandMoreRoundedIcon } from '../common/assets/ExpandMoreRoundedIcon';

type TenantDropdownProps = MuiSelectProps;

const TenantDropdown = (props: TenantDropdownProps) => {
    const { endAdornment, ...rest } = props;
    /**
     * NOTE:
     * This dropdown is just a placeholder currently. Will make this an auto-complete dropdown in a future branch.
     * The mockTenantList will not be used in Production. The data will be set by the values returned by the API.
     */

    const memoTenantList = useMemo(
        () => mockTenantList ?? [],
        [mockTenantList],
    );

    const [selectedTenant, setSelectedTenant] = useRecoilState(tenantStateAtom);
    const [tenantList, setTenantList] = useState<TenantList | null>(
        memoTenantList,
    );

    const memoTenantListHasValues =
        Array.isArray(memoTenantList) && memoTenantList.length > 0;

    useEffect(
        () =>
            memoTenantListHasValues
                ? setTenantList(memoTenantList)
                : setTenantList([]),
        [memoTenantList],
    );

    function handleChange(event: SelectChangeEvent) {
        const tenantToSelect = tenantList?.find(
            (tenant) => tenant.tenantName === event.target.value,
        );
        if (!!tenantToSelect) {
            setSelectedTenant(tenantToSelect);
        }
    }

    const tenantDropdownComponentId = 'tenantDropdownComponent';
    const tenantDropdownLabelId = 'tenantDropdownLabel';
    const tenantDropdownLabelText = 'Tenant';

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel id={tenantDropdownLabelId}>
                {tenantDropdownLabelText}
            </InputLabel>
            <MuiSelect
                variant="outlined"
                label={tenantDropdownLabelText}
                labelId={tenantDropdownLabelId}
                id={tenantDropdownComponentId}
                value={selectedTenant.tenantName ?? ''}
                onChange={handleChange}
                IconComponent={ExpandMoreRoundedIcon}
                {...rest}
            >
                {memoTenantListHasValues &&
                    tenantList?.map((tenant) => {
                        if (!!tenant.tenantName) {
                            return (
                                <MenuItem
                                    key={tenant?.tenantName}
                                    value={tenant?.tenantName}
                                    id={
                                        'tenantDropdownItem' +
                                        tenant?.tenantName
                                    }
                                >
                                    {tenant?.tenantName}
                                </MenuItem>
                            );
                        }
                    })}
            </MuiSelect>
        </FormControl>
    );
};

export default TenantDropdown;
