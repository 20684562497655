import Box from '@mui/material/Box';
import createSvgIcon from '@mui/material/utils/createSvgIcon';

export const GeneralLedgerIcon = createSvgIcon(
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 11.5001V15.5001C4 16.3301 4.67 17.0001 5.5 17.0001C6.33 17.0001 7 16.3301 7 15.5001V11.5001C7 10.6701 6.33 10.0001 5.5 10.0001C4.67 10.0001 4 10.6701 4 11.5001ZM10 11.5001V15.5001C10 16.3301 10.67 17.0001 11.5 17.0001C12.33 17.0001 13 16.3301 13 15.5001V11.5001C13 10.6701 12.33 10.0001 11.5 10.0001C10.67 10.0001 10 10.6701 10 11.5001ZM3.5 22.0001H19.5C20.33 22.0001 21 21.3301 21 20.5001C21 19.6701 20.33 19.0001 19.5 19.0001H3.5C2.67 19.0001 2 19.6701 2 20.5001C2 21.3301 2.67 22.0001 3.5 22.0001ZM16 11.5001V15.5001C16 16.3301 16.67 17.0001 17.5 17.0001C18.33 17.0001 19 16.3301 19 15.5001V11.5001C19 10.6701 18.33 10.0001 17.5 10.0001C16.67 10.0001 16 10.6701 16 11.5001ZM10.57 1.49007L2.67 5.65007C2.26 5.86007 2 6.29007 2 6.75007C2 7.44007 2.56 8.00007 3.25 8.00007H19.76C20.44 8.00007 21 7.44007 21 6.75007C21 6.29007 20.74 5.86007 20.33 5.65007L12.43 1.49007C11.85 1.18007 11.15 1.18007 10.57 1.49007Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>,
    'General Ledger',
);

export const GeneralLedgerPage = () => {
    return (
        <Box>
            <h1>GL goes here</h1>
        </Box>
    );
};
