import { filledInputClasses } from '@mui/material/FilledInput';
import { fmsColors } from './colors';
// eslint-disable-next-line no-restricted-imports

/**
 * This typedef exists just so that we can import the `ThemeTypes`
 * and get the module augmentation that is in there.
 * @typedef {import('./ThemeTypes').Hi} Hi
 **/

/**
 * @param {import('@mui/material/styles').Theme} theme
 * @returns {import('@mui/material/styles').ThemeOptions['components']}
 **/
const getThemeComponentDefaults = (theme) => ({
    MuiSelected: {
        styleOverrides: {
            fill: fmsColors.coolGrey40.value,
        },
    },
    MuiButtonBase: {
        styleOverrides: {
            root: {
                ['&.MuiListItemButton-root']: {
                    ['&.Mui-selected']: {
                        backgroundColor: '#EFEFEF',
                    },
                },
            },
        },
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
                backgroundColor: fmsColors.fmsBackground.value,
                [`& .${filledInputClasses.disabled}`]: {
                    backgroundColor: fmsColors.fmsBackground.value,
                },
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                ['& .MuiAutocomplete-popupIndicator svg']: {
                    fontSize: '30px',
                },
                ['& .MuiAutocomplete-clearIndicator svg']: {
                    fontSize: '24px',
                },
            },
        },
    },
    MuiTableSortLabel: {
        styleOverrides: {
            root: {
                ['& .MuiTableSortLabel-icon']: {
                    fontSize: '0.75em',
                },
            },
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                letterSpacing: 0.4,
            },
            asterisk: {
                display: 'none',
            },
        },
    },
    MuiFormControl: {
        defaultProps: {
            variant: 'outlined',
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: { justifyContent: 'center', m: 2 },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                padding: 4,
            },
            head: {
                padding: '16px 8px',
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                height: 48,
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                paddingBottom: 14,
            },
        },
    },
    MuiRadio: {
        defaultProps: {
            color: 'secondary',
        },
    },
    MuiSwitch: {
        defaultProps: {
            color: 'secondary',
        },
    },
    MuiSelect: {
        defaultProps: {
            variant: 'standard',
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: 'standard',
        },
    },
    MuiInputBase: {
        styleOverrides: {
            /**
             * These style overrides are to prevent default number fields from displaying the up/down arrows which display by default.
             * We should use the custom NumberField components whenever possible, but the MUI x-data-grid components will render the defaults in edit mode.
             */
            root: {
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                '& input[type=number]': {
                    MozAppearance: 'textfield',
                },
            },
        },
    },
    MuiTabs: {
        defaultProps: {
            indicatorColor: 'secondary',
        },
        styleOverrides: {
            root: {
                textAlign: 'center',
                textDecoration: 'none',
                boxSizing: 'content-box',
                margin: '2em',
            },
        },
    },
    MuiTabPanel: {
        styleOverrides: {
            root: {
                margin: '2em',
                paddingTop: '0.125em',
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                ['& .MuiListItemIcon-root svg']: {
                    fontSize: '18px',
                },
                ['& .MuiListItemText-root div']: {
                    fontSize: '1.3714285714285714rem',
                },
                ['& div.MuiListItemText-root']: {
                    marginTop: theme.spacing(0.5),
                    marginBottom: theme.spacing(0.5),
                },
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                color: fmsColors.black80.value,
            },
        },
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                ['& .MuiDataGrid-topContainer']: {
                    '--DataGrid-containerBackground': '#F5F5F5',
                    '--DataGrid-rowBorderColor': '#C4C4C4',
                },
            },
        },
    },
});

export default getThemeComponentDefaults;
