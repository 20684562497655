import VerificationRunDetailResult, {
    VerificationRunDetailRequest,
} from '../api/fmRun/verificationRunDetailResult';

export const mockVerificationRunDetailRequest: VerificationRunDetailRequest = {
    tenantName: 'paperless',
    fmRunId: 123,
};

export const mockVerificationRunDetailResult: VerificationRunDetailResult = [
    {
        FmVerificationRunId: 98765,
        IsVerified: false,
        DateVerified: '11/17/2024',
        IsOverridden: false,
        OverriddenBy: null,
        OverrideAllowed: true,
        Description: 'The verification has 1 failure(s) and 1 warning(s)',
    },
];
