import { Component } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

type ErrorBoundaryState = {
    hasError: boolean;
    error: unknown;
};

class ComponentErrorBoundary extends Component<any, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    render() {
        const { hasError, error } = this.state;

        if (hasError) {
            const errorMessage = error instanceof Error ? error.message : '';

            return (
                <Grid container alignItems="center" paddingTop="15vh" paddingBottom="30vh">
                    <Grid>
                        <Typography>
                            An error occurred while rendering the React Components in this section of the UI. Please
                            check the browser developer console for more information.
                        </Typography>
                        {errorMessage && (
                            <Typography color="red" paddingTop="20px">
                                {errorMessage}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            );
        }

        return <>{this.props.children}</>;
    }
}

export default ComponentErrorBoundary;
